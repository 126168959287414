<template>
  <div class="forgot">
    <div class="forgot_box" v-if="show">
      <p>找回密码</p>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item prop="email">
              <el-input v-model="ruleForm.email" placeholder="请输入你注册时填写的邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="brand">
              <el-input v-model="ruleForm.brand" placeholder="请输入品牌名称"></el-input>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="handleFirm('ruleForm')" :loading="loading">确定</el-button>
          </el-form-item>
      </el-form>
    </div>
    <div v-else style="margin-top:20vh">
      <el-result icon="success" title="邮件发送成功" :subTitle="title">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="emailFirm">确定</el-button>
        </template>
      </el-result>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    var vaildatePass = (rule,value,callback) => {
        if(value == ''){
            callback(new Error("请输入邮箱"));
        }else{
          let re = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
          if(re.test(value)){
            callback();
          }else{
            callback(new Error("请输入正确的邮箱"));
          }
        }
    };
    return {
      ruleForm:{
        email:'',//邮箱
        brand:'',//品牌
      },
      loading:false,
      show:true,
      title:'',
      rules:{
          email: [
              {required:true,message:'请输入邮箱',trigger:'blur'},
              {validator:vaildatePass,trigger:'blur'},
          ],
          brand:[
              {required:true,message:'请输入品牌名称',trigger:'blur'}
          ]
      }
    };
  },
  methods:{
    // 提交
    handleFirm(formName){
      this.$refs[formName].validate((valid) => {
          if (valid) {
              this.loading = true;
              this.$axios.get("/api/v2/ForgotPassword",{
                email:this.ruleForm.email,
                brand:this.ruleForm.brand
              })
              .then((res) => {
                this.loading = false;
                if(res.data.status == 0){
                  this.title = res.data.message;
                  this.show = false;
                }else{
                  this.$message.error(res.data.message);
                }
              }).catch((error) => {
                this.loading = false;
                this.$message.error(error);
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    // 返回首页
    emailFirm(){
      this.$router.push({
        path:'/login'
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.forgot{
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .forgot_box{
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    p{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    }
    .el-input{
      width: 300px;
    }
    .el-button{
      width:300px;
    }
  }
}
</style>