<template>
  <div class="gather">
    <el-tabs v-model="activeName" @tab-click="tabsClick" style="height: 100%">
      <el-tab-pane label="插件数据采集" name="line">
        <div style="display: flex; margin-right: 20px">
          <div style="margin-right: 50px">
            <el-select
              v-model="optValue"
              clearable
              placeholder="请选择平台"
              size="small"
              style="width: 120px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="keyword"
              placeholder="请输入关键词或SPU名称"
              class="gatIpt"
              size="small"
            ></el-input>
            <el-input
              v-model="exclude_keyword"
              placeholder="请输入不包括SPU名称"
              class="gatIpt"
              size="small"
            ></el-input>
            <el-checkbox v-model="is_new" style="margin-left: 20px"
              >只看新产品</el-checkbox
            >
            <el-button type="primary" size="small" class="inquire" @click="clickInquire"
              >查询</el-button
            >
          </div>
          <div>
            <el-button
              size="small"
              class="prim1"
              :loading="delLoading"
              @click="deleteData"
            >
              删除数据
            </el-button>
          </div>
          <el-dropdown trigger="click" class="prim1" @command="monitoring">
            <el-button size="small">
              监控数据<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="监控选中数据">监控选中数据</el-dropdown-item>
              <el-dropdown-item command="监控全部数据">监控全部数据</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" class="prim1" @command="hamdleExport">
            <el-button size="small" :loading="ExpLoading">
              导出数据<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="导出选中数据">导出选中数据</el-dropdown-item>
              <el-dropdown-item command="导出全部数据">导出全部数据</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 中间列表内容 -->
        <div style="margin-top: 20px; flex: 1">
          <el-table
            v-loading="loading"
            :data="tableData"
            height="700px"
            :header-cell-style="{ background: '#fafafa', color: '#333333' }"
            @selection-change="handleSelectionChange"
            style="width: 100%;height：100%"
          >
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column
              prop="platform_name"
              label="平台"
              align="center"
              min-width="80"
            >
            </el-table-column>
            <el-table-column
              prop="keywords"
              label="关键词"
              align="center"
              min-width="120"
            >
            </el-table-column>
            <el-table-column prop="position" label="位置" align="center" min-width="80">
            </el-table-column>
            <el-table-column prop="goods_id" label="ID" align="center" min-width="120">
              <template slot-scope="scope">
                <div class="scope_box">
                  <el-tag type="" effect="dark" size="mini" v-if="scope.row.has == 0">
                    新产品
                  </el-tag>
                  {{ scope.row.goods_id }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="spu_name"
              label="SPU名称"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column prop="spu_img" align="center" label="主图">
              <template slot-scope="scope">
                <div class="scope_box">
                  <el-popover placement="right" width="350" trigger="hover">
                    <el-image
                      style="width: 100%; height: auto"
                      :src="scope.row.spu_img"
                      fit="fill"
                    ></el-image>
                    <el-image
                      slot="reference"
                      style="width: 48px; height: 48px"
                      :src="scope.row.spu_img"
                      fit="fill"
                    ></el-image>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="price" align="center" min-width="80" label="价格">
            </el-table-column>
            <el-table-column
              prop="sales_volume"
              align="center"
              min-width="100"
              label="销量"
            >
            </el-table-column>
            <el-table-column
              prop="shop_name"
              align="center"
              min-width="180"
              label="店铺名称"
            >
            </el-table-column>
            <el-table-column prop="tags" align="center" min-width="150" label="特点">
            </el-table-column>
            <el-table-column prop="create_at" align="center" min-width="120" label="时间">
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="page">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[30, 60, 120, 300, 600]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <!-- 设置数据监控方案 -->
        <el-dialog
          title="设置数据监控方案"
          :visible.sync="dialogTask"
          width="400px"
          @close="taskClose"
        >
          <!-- 选择定时任务 -->
          <el-select
            v-model="taskValue"
            style="width: 100%"
            placeholder="请选择定时任务"
            @change="handleTaskValue"
          >
            <el-option
              v-for="item in task"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogTask = false">取 消</el-button>
            <el-button type="primary" @click="handleTask" :loading="taskLoading"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="在线数据采集" name="plug" v-if="false">
        <gather-list></gather-list>
      </el-tab-pane>
    </el-tabs>
    <!-- 头部按钮 -->
  </div>
</template>

<script>
import Aegis from "aegis-web-sdk";
import gatherList from "../../components/CommonGather/gatherList.vue";
export default {
  components: { gatherList },
  data() {
    return {
      keyword: "", //关键词
      exclude_keyword: "", //不包括SPU名称
      is_new: false, //是否是新产品
      activeName: "line", //tab组件下标
      loading: false,
      tableData: [], //列表数据
      Selection: [], //tb_goods_v1_id
      delLoading: false, //删除数据loading
      taskLoading: false, //监控设置loading
      ExpLoading: false, //导出数据loading
      choose: "", //选择数据和全部数据

      dialogTask: false, //打开监控方案窗口
      taskValue: "", //监控方案value
      task: [], //监控方案列表
      tb_schedule_id: "",

      pageNum: 1, //目前第几页
      pageSize: 30, //默认每页30条
      total: 0, //总条数

      options: [
        {
          value: "淘宝",
          label: "淘宝",
        },
        {
          value: "天猫",
          label: "天猫",
        },
        {
          value: "京东",
          label: "京东",
        },
        {
          value: "拼多多",
          label: "拼多多",
        },
        {
          value: "1688",
          label: "1688",
        },
        {
          value: "唯品会",
          label: "唯品会",
        },
        {
          value: "速卖通",
          label: "速卖通",
        },
      ],
      optValue: "",
    };
  },

  created() {
    const aegis = new Aegis({
      id: "XaKVn5BzYaR3MRyjbO", // 上报 id
      uin: "xxx", // 用户唯一 ID（可选）
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
    });
    aegis.infoAll("上传");
    // 获取列表
    this.gather();

    // 监控数据
    this.load_Schedule();
  },

  methods: {
    tabsClick(tab) {
      this.activeName = tab.name;
    },
    // 获取列表
    gather() {
      this.loading = true;
      this.$axios
        .post("/api/v2/UserReportData", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          platform_name: this.optValue,
          keyword: this.keyword,
          exclude_keyword: this.exclude_keyword,
          is_new: this.is_new,
        })
        .then((res) => {
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            this.tableData = res.data.data;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    //   删除数据
    deleteData() {
      if (this.Selection.length == 0) {
        this.$message({
          message: "请先勾选列表数据",
          type: "warning",
        });
        return false;
      }
      this.$confirm("是否确认删除数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delLoading = true;
        this.$axios
          .post("/api/v6/UserReportDataDelete", {
            id: this.Selection,
          })
          .then((res) => {
            this.delLoading = false;
            if (res.data.status == 0) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.gather();
            }
          })
          .catch(() => {
            this.delLoading = false;
          });
      });
    },
    // 定时任务关闭的回调
    taskClose() {
      this.taskValue = "";
      this.tb_schedule_id = null;
    },
    // 选择定时任务
    handleTaskValue(e) {
      this.tb_schedule_id = e;
    },
    // 监控数据
    monitoring(command) {
      this.choose = command;
      if (command == "监控选中数据" && this.Selection.length == 0) {
        this.$message({
          message: "请先勾选列表数据",
          type: "warning",
        });
        this.taskValue = "";
        return;
      }
      this.dialogTask = true;
    },
    // 导出数据
    hamdleExport(command) {
      this.choose = command;
      if (command == "导出选中数据" && this.Selection.length == 0) {
        this.$message({
          message: "请先勾选列表数据",
          type: "warning",
        });
        return;
      }
      let data = {};
      data.parameter = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        platform_name: this.optValue,
        keyword: this.keyword,
        exclude_keyword: this.exclude_keyword,
        is_new: this.is_new,
      };
      data.ids = this.Selection;
      data.choose = this.choose;
      this.ExpLoading = true;
      this.$axios
        .postData("/api/v3/UserReportDataExport", data)
        .then((res) => {
          this.ExpLoading = false;
          let name = res.headers["content-disposition"];
          let names = name.split("''");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" }); //接收文件流文件
          const fileName = decodeURI(names[1]);
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          this.ExpLoading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 选择定时任务的提交
    handleTask() {
      if (this.tb_schedule_id == null) {
        this.$message({
          type: "error",
          message: "请选择定时任务",
        });
        return;
      }
      this.taskLoading = true;
      this.$axios
        .post("/api/v6/UserReportDataUpdateSchedule", {
          parameter: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            platform_name: this.optValue,
            keyword: this.keyword,
            exclude_keyword: this.exclude_keyword,
            is_new: this.is_new,
          },
          id: this.Selection,
          tb_schedule_id: this.tb_schedule_id,
          choose: this.choose,
        })
        .then((res) => {
          this.taskLoading = false;
          this.dialogTask = false;
          if (res.data.status == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.taskValue = "";
            this.gather();
          }
        })
        .catch((error) => {
          this.taskLoading = false;
          this.taskValue = "";
          this.dialogTask = false;
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    // 获取数据；
    load_Schedule() {
      this.$axios.get("/api/Schedule", {}).then((res) => {
        if (res.data.status == 0) {
          for (let i in res.data.data) {
            this.task.push({
              value: res.data.data[i].id,
              label: res.data.data[i].name,
            });
          }
        }
      });
    },
    // 列表勾选
    handleSelectionChange(val) {
      if (val.length == 0) return (this.Selection = val);
      var arr = []; //为多个元素时，ID会有重复，需要过滤重复的ID
      for (var i = 0; i < val.length; i++) {
        if (arr.indexOf(val[i].id) == -1) {
          arr.push(val[i].id);
        }
      }
      this.Selection = arr;
      console.log(arr);
    },
    // 跳转到平台、
    handleClick(url) {
      window.open(url);
    },
    // 每页显示条数
    handleSizeChange(val) {
      if (val == this.pageSize) return;
      this.pageSize = val;
      this.gather();
    },
    // 当前页面
    handleCurrentChange(val) {
      if (val == this.pageNum) return;
      this.pageNum = val;
      this.gather();
    },
    // 选择平台
    clickInquire() {
      this.pageNum = 1;
      this.gather();
    },
  },
};
</script>
<style lang="scss" scoped>
.gather {
  width: 100%;
  min-width: 1392px;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 1110px;
  }
  .prim1 {
    margin-right: 20px;
  }
  .page {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
  .inquire {
    width: 90px;
    margin-left: 20px;
  }
}
.gatIpt {
  width: 200px;
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>
