<template>
  <div class="gathers">
    <div style="display:flex;margin-right:20px;">
        <div style="margin-right:50px">
            <el-select v-model="optValue" placeholder="请选择平台" size="small" style="width:120px">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <el-input v-model="value" placeholder="请输入关键词" class="gatIpt" size="small"></el-input>
            <el-button type="primary" size="small" class="inquire" @click="clickInquire">查询</el-button>
        </div>
        <!-- <el-dropdown trigger="click" class="prim1" @command="monitoring">
            <el-button size="small">
                删除数据<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="删除选中数据">删除选中数据</el-dropdown-item>
                <el-dropdown-item command="删除全部数据">删除全部数据</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown> -->
        <el-dropdown trigger="click" class="prim1" @command="monitoring">
            <el-button size="small">
                监控数据<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="监控选中数据">监控选中数据</el-dropdown-item>
                <el-dropdown-item command="监控全部数据">监控全部数据</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <download-excel
            :data="json_data"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="抖音在线采集.xls"
        >
            <el-button size="small" type="success">
            导出全部数据
        </el-button>
        </download-excel>
    </div>
    <div style="margin-top:20px;flex:1;">
        <!-- @selection-change="handleSelectionChange" -->
        <el-table
        v-loading="loading"
        :data="tableData.items"
        height="700px"
        :header-cell-style="{ background: '#fafafa', color: '#333333' }"
        style="width: 100%;height：100%">
        <el-table-column
        type="selection"
        align="center"
        width="55">
        </el-table-column>
        <el-table-column
            prop="platform_name"
            label="平台"
            align="center"
            min-width="80">
            <template>
                抖音
            </template>
        </el-table-column>
        <el-table-column
            prop="keyword"
            label="关键词"
            align="center"
            min-width="120">
        </el-table-column>
        <!-- <el-table-column
            prop="position"
            label="位置"
            align="center"
            min-width="80">
        </el-table-column> -->
        <el-table-column
            prop="product_info.gid"
            label="ID"
            align="center"
            min-width="120">
        </el-table-column>
        <el-table-column
            prop="product_info.name"
            label="SPU名称"
            align="center"
            min-width="180">
        </el-table-column>
        <el-table-column
            prop="product_info.img"
            align="center"
            label="主图">
            <template slot-scope="scope">
                <div
                class="scope_box"
                >
                <el-popover placement="right" width="350" trigger="hover">
                    <el-image
                    style="width: 100%; height: auto"
                    :src="scope.row.product_info.img"
                    fit="fill"
                    ></el-image>
                    <el-image
                    slot="reference"
                    style="width: 48px; height: 48px"
                    :src="scope.row.product_info.img"
                    fit="fill"
                    ></el-image>
                </el-popover>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="product_info.show_price"
            align="center"
            min-width="80"
            label="价格">
        </el-table-column>
        <el-table-column
            prop="product_info.extra_info"
            align="center"
            min-width="100"
            label="销量">
        </el-table-column>
        <el-table-column
            prop="aweme_info.author.nickname"
            align="center"
            min-width="180"
            label="店铺名称">
        </el-table-column>
        <el-table-column
            prop="urls"
            align="center"
            min-width="180"
            label="商品链接">
        </el-table-column>
        <el-table-column
            prop="aweme_info.author.unique_id"
            align="center"
            min-width="120"
            label="账号">
        </el-table-column>
        <el-table-column
            prop="aweme_info.desc"
            align="center"
            min-width="150"
            label="特点">
        </el-table-column>
        </el-table>
    </div>
    <div class="page">
        <el-button size="medium" type="primary" plain @click="onClickPage(1)" style="width:100%;" :loading="pageLoad">加载更多</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
        options:[{
          value: '抖音',
          label: '抖音'
        }],
        optValue:'抖音',
        value:'',//关键词
        pageNum:1,
        loading:false,
        search_id:0,
        tableData: {
            items:[]
        }, //列表数据
        pageLoad:false,//加载页面
        
        // 导出的数据
        json_fields:{
            平台:"name",
            关键词:"keyword",
            id:"gid",
            spu名称:"spuName",
            价格:"show_price",
            销量:"extra_info",
            店铺名称:"nickname",
            链接:'url',
            账号:"unique_id",
            特点:"desc"
        },
        json_data:[]
    };
  },
  created() {},
  methods: {
      // 获取列表
    clickInquire(){
        if(this.value == ''){
            this.$message({
                message: '请输入关键词',
                type: "error",
            });
            return;
        }
        if(this.tableData.items.length == 0){
            this.loading = true;
        }else{
            this.pageLoad = true;
        }
        this.$axios
        .get(`/api/douyin/?page_num=${this.pageNum}&kw=${this.value}&search_id=${this.search_id}&dev&author=50f75e2dc01b300aefd53e37ae4c9223&token=shoping1e884d5a5e268cc58f7328df33925506`, {})
        .then((res) => {
          if(res.data.status_code == 0){
                // 处理关键词/商品链接/价格内容
                var row = res.data;
                for(let i in row.items){
                    row.items[i].keyword = row.global_doodle_config.keyword;
                    row.items[i].product_info.show_price = this.abs(row.items[i].product_info.show_price);
                    if(row.items[i].aweme_info){
                        row.items[i].urls = `https://haohuo.jinritemai.com/views/product/item2?sec_author_id=${row.items[i].aweme_info.author.sec_uid}&id=${row.items[i].product_info.gid}`
                    }else{
                        row.items[i].urls = '';
                    }
                }
                if(this.tableData.items.length > 0){
                    this.tableData.items = [...this.tableData.items, ...row.items];
                }else{
                    this.tableData = row;
                }
                this.search_id = res.data.log_pb.impr_id;
                this.jsonData(this.tableData);
          }else{
              this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
          this.loading = false;
          this.pageLoad = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 价格格式化
    abs(val){
        var str = (val/100).toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
    },
    // 数据导出的内容
    jsonData(data){
        let a = [];
        for(let i in data.items){
            let val = {
                name:'抖音',
                keyword:data.global_doodle_config.keyword,
                gid:data.items[i].product_info.gid,
                spuName:data.items[i].product_info.name,
                show_price:data.items[i].product_info.show_price,
                extra_info:data.items[i].product_info.extra_info,
                nickname:"",
                unique_id:"",
                desc:"",
                url:"",
            }
            // aweme_info 有些是没有的，需要判断
            if(data.items[i].aweme_info){
                val.nickname = data.items[i].aweme_info.author.nickname;
                val.unique_id = data.items[i].aweme_info.author.unique_id;
                val.desc = data.items[i].aweme_info.desc,
                val.url = `https://haohuo.jinritemai.com/views/product/item2?sec_author_id=${data.items[i].aweme_info.author.sec_uid}&id=${data.items[i].product_info.gid}`
            }
            a.push(val)
        }
        this.json_data = a;
    },
    // 上下页
    onClickPage(v){
         if(this.value == ''){
            this.$message({
                message: '请输入关键词',
                type: "error",
            });
            return;
        }
        if(this.pageLoad) return;
        this.pageNum = v == 0 ? this.pageNum - 1 : this.pageNum + 1;
        this.clickInquire();
    },
    // 删除 、 监控
    monitoring(){
        console.log('1')
    },
  },
};
</script>

<style lang="scss" scoped>
.gathers{
    width:100%;
    min-height: 500px;
    .inquire{
      width: 90px;
      margin-left: 20px;
    }
    .gatIpt{
        width: 200px;
        margin-left: 20px
    }
    .prim1{
        margin-right: 20px;
    }
    .page {
        margin-top: 32px;
    }
}
</style>
