import axios from "axios";
// import router from "../router";
// import qs from 'qs';

// var baseURL = process.env.VUE_APP_API_URL;
var baseURL = "as";

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      //判断token是否存在
      config.headers.Authorization = "Bearer" + " " + localStorage.token; //将token设置成请求头
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data.StatusCode === 401) {
      window.location.href='http://cas.datamonitor.shop/login?returnUrl=http://www2.datamonitor.shop/'
    }
    return response;
  },
  (error) => {
    console.log(error.response);
    let errs = error.toString();
    if(errs.indexOf('timeout') >= 0){
      return Promise.reject("加载超时，请重试");
    }else if(error.response.status === 401){
      window.location.href='http://cas.datamonitor.shop/login?returnUrl=http://www2.datamonitor.shop/';
      return Promise.reject(error)
    }else if(error.response.status === 400){
      return Promise.reject(error.response.data.errors.serial[0]);
    }
    return Promise.reject(error);
  }
);
export default {
  post (url, data) {
    return axios({
      method: "post",
      url: `${baseURL}${url}`,
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json; charset=utf-8",
      },
      data,
      timeout: 180000,
    });
  },
  post1 (url, data) {
    return axios({
      method: "post",
      url: `${baseURL}${url}`,
      headers: {
        accept: "text/plain",
        "Content-Type": "multipart/form-data;",
      },
      data,
      timeout: 180000,
    });
  },
  postData (url, data) {
    return axios({
      method: "post",
      url: `${baseURL}${url}`,
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json-patch+json",
      },
      responseType: 'blob',
      data,
      timeout: 300000,
    });
  },
  add (url, data, params) {
    return axios({
      method: "post",
      url: `${baseURL}${url}`,
      headers: {
        accept: "text/plain",
        "Content-Type": "text/json",
      },
      data: data,
      params: params,
      timeout: 180000,
    });
  },
  put (url, data) {
    return axios({
      method: "put",
      url: `${baseURL}${url}`,
      data,
      timeout: 180000,
    });
  },
  delete_data (url, post_data) {
    return axios.delete(`${baseURL}${url}`, {
      data: post_data,
      headers: {
        accept: "text/plain",
        "Content-Type": "text/json",
      },
      timeout: 60000,
    });
  },
  delete_params (url, params) {
    return axios({
      method: "delete",
      url: `${baseURL}${url}`,
      headers: {
        accept: "text/plain",
        "Content-Type": "text/json",
      },
      params: params,
      timeout: 60000,
    });
  },
  delete_data_params (url, post_data, params) {
    return axios({
      method: "delete",
      url: `${baseURL}${url}`,
      headers: {
        accept: "text/plain",
        "Content-Type": "text/json",
      },
      data: post_data,
      params: params,
      timeout: 60000,
    });
  },
  delete (url) {
    return axios({
      method: "delete",
      url: `${baseURL}${url}`,
      headers: {
        accept: "text/plain",
        "Content-Type": "text/json",
      },
      timeout: 60000,
    });
  },
  get (url, params) {
    return axios({
      method: "get",
      url: `${baseURL}${url}`,
      params: params,
      timeout: 180000,
    });
  },
};
