<template>
  <div class="updatePassWord">
    <div class="forgot_box">
      <p>更新密码</p>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="newPwd">
                <el-input v-model="ruleForm.newPwd" placeholder="密码"></el-input>
            </el-form-item>
            <el-form-item prop="confirmPwd">
                <el-input v-model="ruleForm.confirmPwd" placeholder="确认密码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>
import { setTimeout } from 'timers';
export default {
  data () {
    var vaildatePass = (rule,value,callback) => {
        if(value == ''){
            callback(new Error("请输入密码"));
        }else{
            if(this.ruleForm.confirmPwd !== ''){
                this.$refs.ruleForm.validateField('confirmPwd');
            }
             callback();
        }
    };
    var vaildatePass2 = (rule,value,callback) => {
        if(value == ''){
            callback(new Error('请再次输入密码'));
        }else if(value !== this.ruleForm.newPwd) {
            callback(new Error('两次输入的密码不一致！'));
        }else{
            callback();
        }
    };
    return {
      ruleForm:{
        newPwd:'',
        confirmPwd:''
      },
      sn:'',
      loading:false,
      rules:{
          newPwd: [
              {required:true,message:'请输入密码',trigger:'blur'},
              {min:7,max:16,message:'密码长度必须大于6位',trigger:'blur'},
              {validator:vaildatePass,trigger:'blur'},
          ],
          confirmPwd:[
              {required:true,message:'请确认密码',trigger:'blur'},
              {min:7,max:16,message:'密码长度必须大于6位',trigger:'blur'},
              {validator:vaildatePass2,trigger:'blur',required:true},
          ]
      }
    };
  },
  created(){
      this.sn = this.$route.query.sn;
  },
  methods:{
    submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.loading = true;
              this.$axios.post("/api/v2/ForgotPassword",{
                  sn:this.sn,
                  password:this.ruleForm.newPwd
              }).then(res => {
                  this.loading = false;
                  if(res.data.status == 0){
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                    setTimeout(() => {
                         this.$router.push({path:'/login'})
                    },1000)
                  }else{
                      this.$message.error(res.data.message);
                  }
              }).catch(error => {
                  this.loading = false;
                  this.$message.error(error);
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.updatePassWord{
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .forgot_box{
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    .forgot_flex{
        display: flex;
        flex-direction: column;
        height: 65px;
    }
    p{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    }
    .el-input{
      width: 300px;
    }
    .el-button{
        width: 300px;
        margin-top: 15px;
    }
  }
}
</style>