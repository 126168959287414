<template>
  <div class="shops">
    <!-- 头部搜索栏目 -->
    <div class="home_function">
      <el-input
        v-model="shop_name"
        placeholder="请输入店铺名称"
        size="small"
        style="width: 200px; margin-left: 40px"
      ></el-input>
      <el-input
        v-model="company"
        placeholder="请输入授权公司"
        size="small"
        style="width: 200px; margin-left: 40px"
      ></el-input>
      <el-input
        v-model="address"
        placeholder="请输入联系方式"
        size="small"
        style="width: 200px; margin-left: 40px"
      ></el-input>
      <el-input
        v-model="serial"
        placeholder="请输入序号"
        size="small"
        style="width: 200px; margin-left: 40px"
      ></el-input>
      <el-input
        v-model="shop_id"
        placeholder="请输入店铺ID"
        size="small"
        style="width: 200px; margin-left: 40px"
      ></el-input>
      <el-input
        v-model="contacts"
        placeholder="请输入对接人"
        size="small"
        style="width: 200px; margin-left: 40px"
      ></el-input>
      <el-input
        v-model="information"
        placeholder="请输入店铺信息"
        size="small"
        style="width: 200px; margin-left: 40px"
      ></el-input>
      <el-button
        size="small"
        type="primary"
        class="prim"
        icon="el-icon-search"
        @click="handleSearch"
        >查询</el-button
      >
      <el-button
        size="small"
        class="prim1"
        @click="(dialogFormVisible = true), (formTitle = '添加店铺')"
        icon="el-icon-circle-plus"
        >添加店铺</el-button
      >
      <el-button size="small" class="prim1" @click="GoodsFile = true" icon="el-icon-upload2">导入</el-button>
      <el-dropdown trigger="click" class="prim1" @command="handleExport">
          <el-button size="small" :loading="exportLoading">
            导出
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="导出全部数据">导出全部数据</el-dropdown-item>
            <el-dropdown-item command="导出选中数据">导出选中数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
    </div>
    <el-table
      v-loading="loading"
      :data="formData"
      style="width: 100%; cursor: pointer"
      :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      :default-sort="{ prop: 'date', order: 'descending' }"
      @row-click="handleCellClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" column-key="choose" :filter-multiple="false" width="55"></el-table-column>
      <el-table-column prop="serial" label="序号" align="center" min-width="60">
      </el-table-column>
      <el-table-column
        prop="platform_name"
        label="平台"
        align="center"
        min-width="90"
        :filters="[
          { text: '淘宝', value: '淘宝' },
          { text: '天猫', value: '天猫' },
          { text: '京东', value: '京东' },
          { text: '拼多多', value: '拼多多' },
          { text: '抖音', value: '抖音' },
          { text: '快手', value: '快手' },
          { text: '闲鱼', value: '闲鱼' },
          { text: '1688', value: '1688' },
          { text: '速卖通', value: '速卖通' },
          { text: '唯品会', value: '唯品会' },
        ]"
        :filter-method="filterHandler"
      >
      </el-table-column>
      <el-table-column
        prop="shop_id"
        label="店铺数字ID"
        align="center"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="seller_nick"
        label="店铺文本ID"
        align="center"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="shop_name"
        label="店铺名称"
        align="center"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="authorization_type"
        label="授权类型"
        align="center"
        min-width="100"
        :filters="[
          { text: '授权', value: '授权' },
          { text: '非授权', value: '非授权' },
          { text: '黑名单', value: '黑名单' },
        ]"
        :filter-method="filterHandler"
      >
      </el-table-column>
      <el-table-column
        prop="contacts"
        label="对接人"
        align="center"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        label="授权公司"
        align="center"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        label="联系方式"
        align="center"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="information"
        label="店铺信息"
        align="center"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="添加时间"
        align="center"
        sortable
        min-width="150"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="power_start_time"
        label="授权开始时间"
        sortable
        align="center"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="power_end_time"
        label="授权结束时间"
        sortable
        align="center"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="check_time"
        label="当前活动"
        align="center"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作"
        align="center"
        width="100"
        fixed="right"
      >
        <template slot-scope="scope">
          <div class="scope_operat">
            <div class="oper_box" @click="shopsDelete(scope.row)">编辑</div>
            <el-popover
              placement="left"
              width="160"
              :ref="`popovers-${scope.row.id}`"
            >
              <p style="text-align: center">是否删除产品？</p>
              <div style="text-align: right; margin-top: 10px">
                <el-button
                  size="mini"
                  type="text"
                  @click="
                    scope._self.$refs[`popovers-${scope.row.id}`].doClose()
                  "
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="handlePopover1(scope.row.id)"
                  :loading="vsbtn"
                  >确定</el-button
                >
              </div>
              <div
                class="oper_box"
                style="width: 100%; text-align: right"
                slot="reference"
              >
                删除
              </div>
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="page" v-if="pageLoad">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[30, 60, 120]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 添加店铺 -->
    <el-dialog
      :title="formTitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      @close="handleCloses"
      width="600px"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
        <el-form-item prop="shop_name" label="店铺名称">
          <el-input
            v-model="form.shop_name"
            autocomplete="off"
            placeholder="店铺名称"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="11">
            <el-form-item prop="serial" label="序号">
              <el-input
                v-model="form.serial"
                autocomplete="off"
                placeholder="序号"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="1">
            <el-form-item prop="platform_name" label="平台">
              <el-select
                v-model="form.platform_name"
                placeholder="请选择平台"
                style="width: 100%"
              >
                <el-option label="淘宝" value="淘宝"></el-option>
                <el-option label="天猫" value="天猫"></el-option>
                <el-option label="京东" value="京东"></el-option>
                <el-option label="拼多多" value="拼多多"></el-option>
                <el-option label="抖音" value="抖音"></el-option>
                <el-option label="快手" value="快手"></el-option>
                <el-option label="闲鱼" value="闲鱼"></el-option>
                <el-option label="1688" value="1688"></el-option>
                <el-option label="速卖通" value="速卖通"></el-option>
                <el-option label="唯品会" value="唯品会"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item prop="authorization_type" label="店铺类型">
              <el-radio v-model="form.authorization_type" label="授权"
                >授权</el-radio
              >
              <el-radio v-model="form.authorization_type" label="非授权"
                >非授权</el-radio
              >
              <el-radio v-model="form.authorization_type" label="黑名单"
                >黑名单</el-radio
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="shop_id" label="数字id">
              <el-input
                v-model="form.shop_id"
                autocomplete="off"
                placeholder="店铺数字id"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="seller_nick" label="文本id">
              <el-input
                v-model="form.seller_nick"
                autocomplete="off"
                placeholder="店铺文本id"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="company" label="授权公司">
          <el-input
            v-model="form.company"
            autocomplete="off"
            placeholder="授权公司"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="11">
            <el-form-item prop="contacts" label="对接人">
              <el-input
                v-model="form.contacts"
                autocomplete="off"
                placeholder="对接人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="1">
            <el-form-item prop="address" label="联系方式">
              <el-input
                v-model="form.address"
                autocomplete="off"
                placeholder="联系方式"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="information" label="店铺信息">
          <el-input
            v-model="form.information"
            autocomplete="off"
            placeholder="店铺信息"
          ></el-input>
        </el-form-item>
        <el-form-item label="授权时间">
          <el-col :span="11">
            <el-date-picker
              v-model="form.power_start_time"
              type="date"
              placeholder="授权开始时间"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center">-</el-col>
          <el-col :span="11">
            <el-date-picker
              v-model="form.power_end_time"
              type="date"
              placeholder="授权结束时间"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="dialogLoad"
          @click="handleSubmit('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 时间侧边栏 -->
    <el-drawer
      title="店铺活动管理"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :size="600"
    >
      <div class="drawers">
        <p class="drawers_info">店铺基本信息</p>
        <el-table :data="rowinfoList" border style="width: 100%">
          <el-table-column prop="shop_id" label="店铺ID" align="center">
          </el-table-column>
          <el-table-column prop="shop_name" label="店铺名称" align="center">
          </el-table-column>
          <el-table-column
            prop="platform_name"
            align="center"
            label="平台"
            width="100"
          >
          </el-table-column>
        </el-table>

        <div style="margin-top: 30px">
          <div class="active">
            <span @click="handleAdds">添加活动</span>
          </div>
          <el-table
            :data="activity"
            style="width: 100%"
            border
            v-loading="actLoading"
          >
            <el-table-column prop="name" label="活动名称" align="center">
            </el-table-column>
            <el-table-column prop="start_time" label="开始时间" align="center">
            </el-table-column>
            <el-table-column prop="end_time" label="结束时间" align="center">
            </el-table-column>
            <el-table-column
              prop="platform_name"
              label="关联SKU"
              align="center"
            >
              <template slot-scope="scope">
                <span
                  @click="handleC1(scope.row)"
                  style="cursor: pointer; color: #3978f7"
                  >关联</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <div class="scope_operat">
                  <div
                    class="oper_box"
                    @click="handleRedact(scope.$index, scope.row)"
                  >
                    编辑
                  </div>
                  <el-popover
                    placement="left"
                    width="160"
                    :ref="`popover-${scope.row.id}`"
                  >
                    <p style="text-align: center">是否删除产品？</p>
                    <div style="text-align: right; margin-top: 10px">
                      <el-button
                        size="mini"
                        type="text"
                        @click="
                          scope._self.$refs[`popover-${scope.row.id}`].doClose()
                        "
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="handlePopover(scope.row.id)"
                        :loading="vsbtn1"
                        >确定</el-button
                      >
                    </div>
                    <div
                      class="oper_box"
                      style="width: 100%; text-align: right"
                      slot="reference"
                    >
                      删除
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
    <!-- 添加活动表单 -->
    <el-dialog
      :title="activeTitle"
      :visible.sync="dialogActive"
      :close-on-click-modal="false"
      @close="handleCloses1"
      width="550px"
    >
      <el-form
        :model="ActiveForm"
        :rules="ActiveRules"
        label-width="80px"
        ref="ruleActive"
      >
        <el-form-item label="活动名称" prop="name">
          <el-input
            v-model="ActiveForm.name"
            autocomplete="off"
            placeholder="活动名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="起止时间" prop="start_end_time">
          <el-date-picker
            v-model="ActiveForm.start_end_time"
            format="yyyy-MM-dd HH"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogActive = false">取 消</el-button>
        <el-button type="primary" @click="handleActive('ruleActive')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 活动关联sku表单 -->
    <el-dialog title="SKU" :visible.sync="skuDialog" width="410px">
      <div class="skuList">
        <el-checkbox
          v-model="skuAll"
          style="margin-bottom: 20px"
          @change="handleSkuAll"
          >全部sku</el-checkbox
        >
        <el-cascader
          :options="skuForm"
          v-model="skuArray"
          :props="{ multiple: true }"
          @change="handleCascader"
          ref="tree"
        ></el-cascader>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="skuDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleCascaderForm"
          :loading="skuLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="上传店铺" :visible.sync="GoodsFile" @close="GoodsFile = false" width="450px">
      <div class="GoodsFiles">
        <div class="GoodsFiles_Top_left">
          <!-- :on-success="upLoadImg"
            :on-error="errorImg" -->
          <el-upload
            class="upload-demo"
            ref="upload"
            :limit="1"
            :file-list="uploadFile"
            action="action"
            :multiple="false"
            :auto-upload="false"
            :http-request="allUpLoad"
            :on-change="handleChange"
            :on-remove="handleRemove"
          >
            <el-button slot="trigger" type="primary">选择文件</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过3M</div>
          </el-upload>
        </div>
        <div class="GoodsFiles_Top_right">
          <p>
            上传格式模板：
            <a class="upLoad_Modules" :href="hrefs" target="_blank">下载模板</a>
          </p>
        </div>
        <div class="GoodsFiles_footer">
          <div>
            <el-button type="text" size="small" style="margin-right: 15px" @click="GoodsFile = false">取消</el-button>
          </div>
          <div>
            <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload" :loading="fileLoad">上传到服务器</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Aegis from "aegis-web-sdk";
export default {
  data() {
    return {
      shop_name: "", //店铺名称
      company: "", //授权公司
      address: "", //联系方式
      serial: "", //序号
      shop_id: "", //店铺ID
      contacts: "", //对接人
      information: "", //店铺信息
      platform_name: "", //平台
      authorization_type: "", //店铺类型

      formData: [],
      pageNum: 1, //目前第几页
      pageSize: 30, //默认每页30条
      total: 0, //总条数
      loading: true,
      pageLoad: false,
      vsbtn1: false,

      // 添加店铺内容
      dialogFormVisible: false,
      dialogLoad: false,
      formTitle: "添加店铺", //标题
      form: {
        serial: "", //序号
        platform_name: "", //平台
        shop_id: "", //店铺ID
        seller_nick: "", //店铺文本ID
        shop_name: "", //店铺名称
        authorization_type: "授权", //店铺类型
        contacts: "", //对接人
        company: "", //授权公司
        address: "", //联系方式
        information: "", //店铺信息
        power_start_time: "", //授权开始时间
        power_end_time: "", //授权结束时间
      },
      formLabelWidth: "120px",

      // 表单校验
      rules: {
        platform_name: [
          { required: true, message: "请选择平台", trigger: "change" },
        ],
        seller_nick: [
          { required: true, message: "请输入店铺文本ID", trigger: "blur" },
        ],
        authorization_type: [
          { required: true, message: "请选择店铺类型", trigger: "change" },
        ],
        power_start_time: [
          {
            required: true,
            message: "请选择授权开始时间",
            trigger: "change",
          },
        ],
        power_end_time: [
          {
            required: true,
            message: "请选择授权结束时间",
            trigger: "change",
          },
        ],
      },

      //   侧边栏
      drawer: false,
      direction: "rtl",
      //   侧边栏接收信息
      rowInfo: {},
      rowinfoList: [],
      //   活动
      activity: [],
      actLoading: true,

      dialogActive: false,
      ActiveForm: {
        name: "", //活动名称
        start_time: "", //授权开始时间
        end_time: "", //授权结束时间
        start_end_time: "",
      },
      activeTitle: "添加活动",
      ActiveRules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        start_end_time: [
          {
            required: true,
            message: "请选择活动时间",
            trigger: "change",
          },
        ],
      },
      vsbtn: false, //确认删除的loading

      // SKU窗口
      skuDialog: false,
      // 是否全部sku
      skuAll: false,
      // sku列表
      skuForm: [],
      // 点击选择的sku列表
      skuArray: [],
      //   loading
      skuLoading: false,
      //   上级传过来的内容
      skuItem: {},

      // 导入
      GoodsFile: false,
      // 请求头
      headers: {
        Authorization: 'Bearer' + ' ' + localStorage.token
      },
      uploadFile: [],
      // 上传文件参数
      upload: {
        tb_prices_level_id: '',
        tb_schedule_id: ''
      },
      fileLoad: false,
      hrefs: 'https://datamonitorshop.oss-cn-hangzhou.aliyuncs.com/templet/%E5%BA%97%E9%93%BA%E4%B8%8A%E4%BC%A0.xlsx',

      // 导出
      exportLoading: false, //导出loading
      // 勾选内容
      Selection: [], //勾选的内容进过筛选的数据
      SelectionData: [], //勾选内容未进过筛选，用于判断是否全部选择
      seleLoading: false,
      dialogTask: false,
      choose: '' //此页数据和全部数据
    };
  },

  created() {
    const aegis = new Aegis({
      id: "XaKVn5BzYaR3MRyjbO", // 上报 id
      uin: "xxx", // 用户唯一 ID（可选）
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
    });
    aegis.infoAll("上传");
    this.shops();
  },

  methods: {
    // 列表勾选
    handleSelectionChange(val) {
      this.SelectionData = val;
      if (val.length == 0) return (this.Selection = val);
      var arr = []; //为多个元素时，ID会有重复，需要过滤重复的ID
      var arrSku = []; //多SKU设置
      var tb_skus_id = []; //记录tb_skus_id，使用更新价格级别SkusV1UpdatePricesLevel API使用到
      for (var i = 0; i < val.length; i++) {
        if (arr.indexOf(val[i].tb_goods_v1_id) == -1) {
          arr.push(val[i].tb_goods_v1_id);
          tb_skus_id.push(val[i].tb_skus_id);
        }
      }
      for (var k = 0; k < val.length; k++) {
        // 设置SKU的元素
        if (arrSku.indexOf(val[k].tb_skus_id) == -1) {
          arrSku.push(val[k].tb_skus_id);
        }
      }
      this.Selection = arr;
    },
    // 商品导出
    handleExport(command) {
      if (this.Selection.length == 0 && command == '导出选中数据') {
        this.$message({
          message: '请先勾选列表内容',
          type: 'warning'
        });
        return false;
      }

      let data = {};
      data.pageNum = this.pageNum;
      data.pageSize = this.pageSize;
      data.serial = this.serial == '' ? null : parseInt(this.serial);
      data.platform_name = this.platform_name;
      data.shop_id = this.shop_id;
      data.shop_name = this.shop_name;
      data.authorization_type = this.authorization_type;
      data.contacts = this.contacts;
      data.company = this.company;
      data.address = this.address;
      data.information = this.information;
      this.exportLoading = true;
      this.$axios
        .postData('/api/v6/ShopsExport', data)
        .then(res => {
          this.exportLoading = false;
          let name = res.headers['content-disposition'];
          let names = name.split("''");
          let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }); //接收文件流文件
          const fileName = decodeURI(names[1]);
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch(err => {
          this.exportLoading = false;
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    // 提交上传商品文件
    submitUpload() {
      console.log('1');
      this.$refs.upload.submit();
    },
    // 导入
    allUpLoad(param) {
      console.log(param);
      const _file = param.file;
      const formData = new FormData();
      formData.append('file', _file);
      this.fileLoad = true;
      this.$axios
        .post1(`/api/v6/ShopsUpload`, formData)
        .then(res => {
          this.fileLoad = false;
          this.$alert(`${res.data.message}`, '提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.GoodsFile = false;
              this.$refs.upload.clearFiles();
              this.load_SkusV1();
            }
          });
        })
        .catch(() => {
          this.fileLoad = false;
          this.$message({
            type: 'error',
            message: '导入失败'
          });
        });
    },
    // 文件状态改变时
    handleChange(file, fileList) {
      console.log(fileList);
      // this.$refs.upload.clearFiles();
      // this.uploadFile = [{name:file.name,url:file.url}];
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    //   打开添加活动
    handleAdds() {
      this.activeTitle = "添加活动";
      this.ActiveForm = {
        name: "", //活动名称
        start_time: "", //授权开始时间
        end_time: "", //授权结束时间
        start_end_time: "",
      };
      this.dialogActive = true;
    },
    //   关闭侧边栏
    handleClose(done) {
      done();
      this.rowInfo = {};
    },
    // 打开店铺基本信息侧边栏
    handleCellClick(row, column) {
      if (column.label !== "操作") {
        this.rowInfo = row;
        this.rowinfoList = [];
        this.rowinfoList.push({
          shop_id: row.shop_id,
          shop_name: row.shop_name,
          platform_name: row.platform_name,
        });
        this.drawer = true;
        this.shopsPromot(row.id);
      }
    },
    // 获取活动列表
    shopsPromot(id) {
      this.actLoading = true;
      this.$axios
        .get("/api/ShopsPromotions?tb_shops_id=" + id, {})
        .then((res) => {
          console.log(res);
          if (res.data.status == 0) {
            this.activity = res.data.data;
          }
          this.actLoading = false;
        });
    },
    //   获取数据
    shops() {
      this.loading = true;
      this.$axios
        .post("/api/Shops", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            this.formData = res.data.data;
          }
          this.loading = false;
          this.pageLoad = true;
        });
    },
    // 查询内容
    handleSearch() {
      this.pageNum = 1;
      let data = {};
      if (this.shop_name != "") {
        data.shop_name = this.shop_name;
      }
      if (this.company != "") {
        data.company = this.company;
      }
      if (this.address != "") {
        data.address = this.address;
      }
      if (this.serial != "") {
        data.serial = this.serial;
      }
      if (this.shop_id != "") {
        data.shop_id = this.shop_id;
      }
      if (this.contacts != "") {
        data.contacts = this.contacts;
      }
      if (this.information != "") {
        data.information = this.information;
      }
      data.pageNum = this.pageNum;
      data.pageSize = this.pageSize;
      this.loading = true;
      this.$axios
        .post("/api/Shops", data)
        .then((res) => {
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            this.formData = res.data.data;
          }
          this.loading = false;
          this.pageLoad = true;
        })
        .catch((error) => {
          this.$message({
            message: error,
            type: "error",
          });
          this.loading = false;
          this.pageLoad = true;
        });
    },
    // 添加商品窗口关闭
    handleCloses() {
      let a = {
        serial: "", //序号
        platform_name: "", //平台
        shop_id: "", //店铺ID
        seller_nick: "", //店铺文本ID
        shop_name: "", //店铺名称
        authorization_type: "授权", //店铺类型
        contacts: "", //对接人
        company: "", //授权公司
        address: "", //联系方式
        information: "", //店铺信息
        power_start_time: "", //授权开始时间
        power_end_time: "", //授权结束时间
      };
      this.form = a;
      this.formTitle = "添加店铺";
      this.$refs.ruleForm.resetFields();
    },
    handleCloses1() {
      let a = {
        name: "", //活动名称
        start_time: "", //授权开始时间
        end_time: "", //授权结束时间
      };
      this.ActiveForm = a;
      this.activeTitle = "添加活动";
      this.$refs.ruleActive.resetFields();
    },
    // 商品编辑
    shopsDelete(row) {
      console.log(row);
      let a = {
        address: row.address,
        authorization_type: row.authorization_type,
        company: row.company,
        contacts: row.contacts,
        create_time: row.create_time,
        id: row.id,
        information: row.information,
        platform_name: row.platform_name,
        power_end_time: row.power_end_time,
        power_start_time: row.power_start_time,
        seller_nick: row.seller_nick,
        serial: row.serial,
        shop_id: row.shop_id,
        shop_name: row.shop_name,
        shop_url: row.shop_url,
      };
      this.form = a;
      this.formTitle = "编辑店铺";
      this.dialogFormVisible = true;
    },
    // 添加店铺
    handleSubmit(formName) {
      let form = this.form;
      this.dialogLoad = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.$axios
              .put("/api/Shops", {
                id: form.id,
                serial: form.serial,
                platform_name: form.platform_name,
                shop_id: form.shop_id,
                seller_nick: form.seller_nick,
                shop_name: form.shop_name,
                authorization_type: form.authorization_type,
                contacts: form.contacts,
                company: form.company,
                address: form.address,
                information: form.information,
                power_start_time: form.power_start_time,
                power_end_time: form.power_end_time,
              })
              .then((res) => {
                this.dialogLoad = false;
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.loading = false;
                this.pageLoad = true;
                this.shops();
              })
              .catch((err) => {
                this.$message({
                  message: err,
                  type: "error",
                });
                this.dialogLoad = false;
              });
          } else {
            this.$axios
              .post("/api/ShopsCreate", {
                serial: form.serial,
                platform_name: form.platform_name,
                shop_id: form.shop_id,
                seller_nick: form.seller_nick,
                shop_name: form.shop_name,
                authorization_type: form.authorization_type,
                contacts: form.contacts,
                company: form.company,
                address: form.address,
                information: form.information,
                power_start_time: form.power_start_time,
                power_end_time: form.power_end_time,
              })
              .then((res) => {
                this.dialogLoad = false;
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.loading = false;
                this.pageLoad = true;
                this.shops();
              })
              .catch((err) => {
                this.$message({
                  message: err,
                  type: "error",
                });
                this.dialogLoad = false;
              });
          }
        } else {
          console.log("error submit!!");
          this.dialogLoad = false;
          return false;
        }
      });
    },
    // 添加活动
    handleActive(ruleActive) {
      this.$refs[ruleActive].validate((valid) => {
        if (valid) {
          let form = this.ActiveForm;
          if (this.activeTitle == "添加活动") {
            this.$axios
              .post("/api/ShopsPromotions", {
                name: form.name,
                start_time: form.start_end_time[0],
                end_time: form.start_end_time[1],
                start_end_time: form.start_end_time,
                tb_shops_id: parseInt(this.rowInfo.id),
              })
              .then((res) => {
                console.log(res);
                if (res.data.status == 0) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });
                  this.dialogActive = false;
                  this.resetForm("ruleActive");
                  this.shopsPromot(this.rowInfo.id);
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              });
          } else {
            this.$axios
              .put("/api/ShopsPromotions", {
                id: form.id,
                name: form.name,
                start_time: form.start_end_time[0],
                end_time: form.start_end_time[1],
                start_end_time: form.start_end_time,
                tb_shops_id: parseInt(this.rowInfo.id),
              })
              .then((res) => {
                console.log(res);
                if (res.data.status == 0) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });
                  this.activeTitle = "添加活动";
                  this.dialogActive = false;
                  this.resetForm("ruleActive");
                  this.shopsPromot(this.rowInfo.id);
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑活动
    handleRedact(index, row) {
      console.log(index);
      console.log(row);
      // 修改标题
      this.activeTitle = "编辑活动";
      // 显示编辑活动弹框
      this.dialogActive = true;
      // 加入需要编辑的内容
      let form = {};
      form.id = row.id;
      form.name = row.name;
      form.start_time = row.start_time;
      form.end_time = row.end_time;
      form.start_end_time = [];
      if (row.start_time) {
        form.start_end_time.push(row.start_time);
      }
      if (row.end_time) {
        form.start_end_time.push(row.end_time);
      }
      this.ActiveForm = form;
    },
    // 清除内容
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 删除活动
    handlePopover(id) {
      this.vsbtn = true;
      this.$axios.delete_params("/api/ShopsPromotions", { id }).then((res) => {
        console.log(res);
        this.vsbtn = false;
        if (res.data.status == 0) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.$refs[`popover-${id}`].doClose();
          this.shopsPromot(this.rowInfo.id);
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    // 删除产品列表
    handlePopover1(id) {
      this.vsbtn1 = true;
      this.$axios
        .delete_params("/api/Shops", { id })
        .then((res) => {
          this.vsbtn1 = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$refs[`popovers-${id}`].doClose();
            this.shops();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.vsbtn1 = false;
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 关联SKU
    handleC1(row) {
      this.skuItem = row;
      this.skuForm = [];
      this.skuArray = [];
      this.skuDialog = true;
      this.$axios.get("/api/ProductsSkusCascader", {}).then((res) => {
        this.skuForm = res.data.data;
        this.ProductsSkus(row.id);
      });
    },
    // 获取店铺活动关联产品sku
    ProductsSkus(id) {
      this.$axios
        .get("/api/ShopsPromotionsProductsSkus?id=" + id, {})
        .then((res) => {
          this.skuArray = res.data.data;
        });
    },
    // 是否全选sku
    handleSkuAll(e) {
      this.skuAll = e;
    },
    // 选中的sku
    handleCascader(e) {
      console.log(e);
      this.skuArray = e;
    },
    // sku保存
    handleCascaderForm() {
      this.skuLoading = true;
      if (this.skuArray.length == 0 && !this.skuAll) {
        this.skuLoading = false;
        this.$message({
          message: "请选择sku",
          type: "error",
        });
        return false;
      }
      if (this.skuAll) {
        this.skuArray = [];
      }
      this.$axios
        .put("/api/ShopsPromotionsProductsSkus", {
          all: this.skuAll,
          tb_shops_promotions_id: this.skuItem.id,
          tb_products_skus_id: this.skuArray,
        })
        .then((res) => {
          this.skuLoading = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.skuDialog = false;
            this.skuForm = [];
            this.skuArray = [];
            this.skuAll = false;
            this.shopsPromot(this.rowInfo.id);
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    // 每页显示条数
    handleSizeChange(val) {
      if (val == this.pageSize) return;
      this.pageSize = val;
      this.shops();
    },
    // 当前页面
    handleCurrentChange(val) {
      if (val == this.pageNum) return;
      this.pageNum = val;
      this.shops();
    },
  },
};
</script>

<style lang="scss" scoped>
.shops {
  width: 100%;
  min-width: 1392px;
  min-height: 500px;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 1110px;
  }
  .home_function {
    width: 100%;
    margin-bottom: 24px;
    .el-input {
      margin-bottom: 10px;
    }
    .prim {
      background-color: #3978f7;
      margin-left: 40px;
      width: 80px;
      @media screen and (max-width: 1400px) {
        margin-left: 25px;
      }
    }
    .prim1 {
      margin-left: 40px;
      width: 100px;
      @media screen and (max-width: 1400px) {
        margin-left: 25px;
      }
    }
  }
}
.page {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.drawers {
  width: 100%;
  padding: 20px;
  .drawers_info {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.active {
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 14px;
    color: #3978f7;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.scope_operat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .oper_box {
    color: #3978f7;
    text-align: center !important;
    cursor: pointer;
    padding: 3px 0;
  }
}
.skuList {
  display: flex;
  flex-direction: column;
}
.GoodsFiles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 200px;
  .GoodsFiles_Top_left {
    margin-top: 10px;
    max-width: 198px;
  }
  .GoodsFiles_Top_right {
    width: 200px;
    p {
      font-size: 14px;
      color: #333333;
      line-height: 1.8;
    }
  }
  .GoodsFiles_selete {
    flex: 1;
    display: flex;
    min-width: 300px;
    margin: 15px 0;
    width: 100%;
    height: 45px;
    .el-select {
      margin: 0 10px;
    }
  }
  .GoodsFiles_footer {
    flex: 1;
    display: flex;
    min-width: 300px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
  }
}
.el-upload__tip {
  text-align: left !important;
}
.upLoad_Modules {
  color: #3978f7;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.skuList {
  padding-bottom: 80px;
  .el-cascader {
    width: 100%;
  }
}
.upload-demo {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
