import Vue from "vue";
import VueRouter from "vue-router";
import Remind from '@/views/Remind/Remind';
import After from '@/views/Home/After';
import User from '@/views/UserInfo/userInfo';
import Task from '@/views/TimedTask/task';
import Addtask from '@/views/TimedTask/addTask';
import shopAdmin from '@/views/shopAdmin/shopAdmin';
import priceSystem from '@/views/PriceSystem/priceSystem';
import gather from '@/views/Gather/index';
import Forgot from '@/views/Login/Forgot';
import Update from '@/views/Login/UpdatePassword';

Vue.use(VueRouter);

const routes = [
  {
    path:'/login',
    name:'login',
    meta:{
      title: '电商数据监控系统'
    },
    component: () => import("@/views/Login/Login"),
  },
  {
    path: "/loginForgot",
    name: "loginForgot",
    meta:{
      requireAuth:true,
      title: '电商数据监控系统'
    },
    component:Forgot,
  },
  {
    path: "/UpdatePassword",
    name: "UpdatePassword",
    meta:{
      requireAuth:true,
      title: '电商数据监控系统'
    },
    component:Update,
  },
  {
    path: "/",
    meta:{
      title: '电商数据监控系统'
    },
    component: () => import("@/views/main"),
    children: [
      {
        path: "/",
        name: "home",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component: () => import("@/views/Home/Home"),
      },
      {
        path: "/add",
        name: "add",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component: () => import("@/views/Home/Add"),
      },
      {
        path: "/after/:id",
        name: "after",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:After,
      },
      {
        path: "/user",
        name: "user",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:User,
      },
      {
        path: "/remind",
        name: "remind",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:Remind,
      },
      {
        path: "/task",
        name: "task",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:Task,
      },
      {
        path: "/addTask",
        name: "addTask",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:Addtask,
      },
      {
        path: "/shops",
        name: "shops",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:shopAdmin,
      },
      {
        path: "/priceSystem",
        name: "priceSystem",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:priceSystem,
      },
      {
        path: "/gather",
        name: "gather",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component:gather,
      },
      {
        path: "/issue",
        name: "issue",
        meta:{
          requireAuth:true,
          title: '电商数据监控系统'
        },
        component: () => import("@/views/Issue/Issue"),
      },
    ],
  },
  {
    path: "*",
    meta:{
      title: '电商数据监控系统'
    },
    component: () => import("@/views/Home/Home"),
  },
];
const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
