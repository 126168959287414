<template>
  <!-- 价格级别 -->
  <div>
    <div class="system_box" style="width: 600px">
      <div class="system_add">
        <div class="system_add_item" @click="handleAdd">添加</div>
      </div>
      <el-table
        v-loading="loading"
        :data="firstData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      >
        <el-table-column prop="name" label="名称" align="center" width="100px">
        </el-table-column>
        <el-table-column prop="start_time" label="开始时间" align="center">
        </el-table-column>
        <el-table-column prop="end_time" label="结束时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <div class="scope_operat">
              <div class="oper_box" @click="handleRedact(scope.row)">编辑</div>
              <el-popover placement="left" width="160" :ref="`popover1-${scope.row.id}`">
                <p style="text-align: center">是否删除产品？</p>
                <div style="text-align: right; margin-top: 10px">
                  <el-button
                    size="mini"
                    type="text"
                    @click="scope._self.$refs[`popover1-${scope.row.id}`].doClose()"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="handlePopover(scope.row.id)"
                    :loading="firstShow"
                    >确定</el-button
                  >
                </div>
                <div
                  class="oper_box"
                  style="width: 100%; text-align: right"
                  slot="reference"
                >
                  删除
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="first" width="500px">
      <el-form
        :model="firstForm"
        label-width="80px"
        :rules="ActiveRules"
        ref="ruleActive"
      >
        <el-form-item label="级别名称" prop="name">
          <el-input
            v-model="firstForm.name"
            autocomplete="off"
            placeholder="请输入级别名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="起止时间">
          <el-date-picker
            v-model="firstForm.start_end_time"
            style="width: 100%"
            format="yyyy-MM-dd HH"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="first = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleFirstFirm('ruleActive')"
          :loading="firstLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    //内容
    firstData: {
      type: Array,
    },
  },
  data() {
    return {
      firstShow: false, //列表删除loading
      firstLoading: false, //提交登录
      first: false, //添加窗口
      // 添加的内容
      firstForm: {
        name: "",
        start_time: null,
        end_time: null,
        start_end_time: [],
      },
      ActiveRules: {
        name: [{ required: true, message: "请输入级别名称", trigger: "blur" }],
      },
    };
  },

  mounted() {},

  methods: {
    /**
     *
     * 添加价格级别
     *
     */
    handleAdd() {
      this.firstForm = {
        name: "",
        start_time: "",
        end_time: "",
        start_end_time: [],
      };
      this.first = true;
    },
    /**
     *
     * 添加
     *
     */
    handleFirstFirm(ruleActive) {
      this.$refs[ruleActive].validate((valid) => {
        if (valid) {
          this.firstLoading = true;
          //   编辑
          if (this.firstForm.id) {
            this.$axios
              .put("/api/v6/PricesLevel", {
                id: this.firstForm.id,
                name: this.firstForm.name,
                // start_time: this.firstForm.start_end_time.length > 0 ? this.firstForm.start_end_time[0] : null,
                // end_time: this.firstForm.start_end_time.length > 0 ? this.firstForm.start_end_time[1] : null,
                start_end_time: this.firstForm.start_end_time,
              })
              .then((res) => {
                console.log(res);
                this.firstLoading = false;
                if (res.data.status == 0) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });
                  this.first = false;
                  this.firstForm = {
                    name: "",
                    start_time: "",
                    end_time: "",
                    start_end_time: [],
                  };
                  this.$emit("handleThird");
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                this.firstLoading = false;
                this.firstForm = {
                  name: "",
                  start_time: "",
                  end_time: "",
                  start_end_time: [],
                };
                this.$message({
                  message: error,
                  type: "error",
                });
              });
          } else {
            this.$axios
              .post("/api/v6/PricesLevel", {
                name: this.firstForm.name,
                start_time: this.firstForm.start_time,
                end_time: this.firstForm.end_time,
                start_end_time: this.firstForm.start_end_time,
              })
              .then((res) => {
                console.log(res);
                this.firstLoading = false;
                if (res.data.status == 0) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });
                  this.first = false;
                  this.firstForm = {
                    name: "",
                    start_time: "",
                    end_time: "",
                    start_end_time: [],
                  };
                  this.$emit("handleThird");
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                this.firstLoading = false;
                this.firstForm = {
                  name: "",
                  start_time: "",
                  end_time: "",
                  start_end_time: [],
                };
                this.$message({
                  message: error,
                  type: "error",
                });
              });
          }
        } else {
          console.log("error submit!!");
          return;
        }
      });
    },
    // 编辑
    handleRedact(row) {
      let a = {
        id: row.id,
        name: row.name,
        // start_time: row.start_time,
        // end_time: row.end_time,
        start_end_time: [],
      };
      if (row.start_time) {
        a.start_end_time.push(row.start_time);
      }
      if (row.end_time) {
        a.start_end_time.push(row.end_time);
      }
      // row.start_time = null;
      // row.end_time = null;
      this.firstForm = a;
      this.first = true;
    },
    // 删除产品列表
    handlePopover(id) {
      this.firstShow = true;
      this.$axios
        .delete_params("/api/v6/PricesLevel", { id })
        .then((res) => {
          this.firstShow = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$refs[`popover1-${id}`].doClose();
            this.$emit("handleThird");
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.firstShow = false;
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.system_box {
  .system_add {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    .system_add_item {
      color: $bg;
      cursor: pointer;
    }
  }
}
.scope_operat {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  .oper_box {
    color: #3978f7;
    text-align: center !important;
    cursor: pointer;
    padding: 3px 0;
  }
}
</style>
