<template>
  <div class="remind">
    <el-table
      v-loading="loading"
      :data="tableItem"
      style="width: 100%"
      :header-cell-style="{ background: '#fafafa', color: '#333333' }"
    >
      <el-table-column
        prop="check_time"
        label="日期"
        align="center"
        min-width="150"
      >
      </el-table-column>
      <el-table-column prop="title" label="宝贝名称" align="center" width="300">
        <template slot-scope="scope">
          <div
            class="scope_box"
            style="cursor: pointer"
            @click="handleClick(scope.row.shop_url)"
          >
            <el-popover placement="right" width="350" trigger="hover">
              <el-image
                style="width: 100%; height: auto"
                :src="scope.row.pic_url"
                fit="fill"
              ></el-image>
              <el-image
                slot="reference"
                style="width: 48px; height: 48px"
                :src="scope.row.pic_url"
                fit="fill"
              ></el-image>
            </el-popover>
            <span>{{ scope.row.original_title }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="platform_name" label="平台" align="center">
      </el-table-column>
      <el-table-column prop="device" label="端" align="center">
      </el-table-column>
      <el-table-column prop="nick" label="掌柜名称" align="center">
        <template slot-scope="scope">
          <div
            class="scope_box"
            style="cursor: pointer"
            @click="handleClick(scope.row.shop_url)"
          >
            <span>{{ scope.row.nick }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="skuTitle" label="SKU" align="center" width="280">
        <template slot-scope="scope">
          <div class="scope_box1">
            <el-popover placement="right" width="350" trigger="hover">
              <el-image
                style="width: 100%; height: auto"
                :src="scope.row.sku_pic_url"
                fit="fill"
              ></el-image>
              <el-image
                slot="reference"
                style="width: 48px; height: 48px"
                :src="scope.row.sku_pic_url"
                fit="fill"
              ></el-image>
            </el-popover>
            <span>{{ scope.row.skus_title }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="current_price" label="到手价" align="center">
      </el-table-column>
      <el-table-column prop="price_range" label="指导价" align="center">
      </el-table-column>
      <el-table-column prop="screenshot_url" label="截图" align="center">
        <template slot-scope="scope">
          <div class="scope_box">
            <el-popover placement="left" width="350" trigger="hover">
              <el-image
                style="width: 100%; height: auto"
                :src="scope.row.screenshot_url"
                fit="fill"
              ></el-image>
              <el-image
                slot="reference"
                style="width: 48px; height: 48px"
                :src="scope.row.screenshot_url"
                fit="fill"
              ></el-image>
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="page" v-if="pageLoad">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-sizes="[30,60,120]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      pageLoad: false,
      tableItem: [],
      srcList: [], //截图
      pageNum: 1, //目前第几页
      pageSize: 30, //默认每页10条
      total: 0, //总条数
    };
  },
  created() {
    this.LowPrice();
  },
  methods: {
    // 获取数据；
    LowPrice() {
      this.loading = true;
      this.$axios
        .post("/api/LowPrice", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            this.tableItem = res.data.data;
            this.srcList.push(this.tableItem.screenshot_url);
          }
          this.loading = false;
          this.pageLoad = true;
        });
    },
    // 每页显示条数
    handleSizeChange(val) {
      if (val == this.pageSize) return;
      this.pageSize = val;
      this.LowPrice();
    },
    // 当前页面
    handleCurrentChange(val) {
      if (val == this.pageNum) return;
      this.pageNum = val;
      this.LowPrice();
    },
    // 商品url跳转
    handleClick(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.remind {
  width: 100%;
  min-width: 1392px;
  min-height: 500px;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 1000px;
  }
  .scope_box {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 8px;
      color: #3978f7;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .scope_box1 {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 8px;
    }
  }
}
.page {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
</style>
