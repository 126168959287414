<template>
  <!-- 店铺体系 -->
  <div>
    <div class="system_box"
         style="width: 400px">
      <div class="system_add">
        <div class="system_add_item"
             @click="first = true">添加</div>
      </div>
      <el-table v-loading="loading"
                :data="firstData"
                style="width: 100%"
                border
                :header-cell-style="{ background: '#fafafa', color: '#333333' }">
        <el-table-column prop="name"
                         label="体系名称"
                         align="center">
        </el-table-column>
        <el-table-column label="操作"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <div class="scope_operat">
              <div class="oper_box"
                   @click="handleRedact(scope.row)">编辑</div>
              <el-popover placement="left"
                          width="160"
                          :ref="`popover-${scope.row.id}`">
                <p style="text-align: center">是否删除产品？</p>
                <div style="text-align: right; margin-top: 10px">
                  <el-button size="mini"
                             type="text"
                             @click="
                      scope._self.$refs[`popover-${scope.row.id}`].doClose()
                    ">取消</el-button>
                  <el-button type="primary"
                             size="mini"
                             @click="handlePopover(scope.row.id)"
                             :loading="firstShow">确定</el-button>
                </div>
                <div class="oper_box"
                     style="width: 100%; text-align: right"
                     slot="reference">
                  删除
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="first"
               width="500px">
      <el-form :model="firstForm">
        <el-form-item label="体系名称"
                      label-width="70px">
          <el-input v-model="firstForm.name"
                    autocomplete="off"
                    placeholder="请输入体系名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="first = false">取 消</el-button>
        <el-button type="primary"
                   @click="handleFirstFirm"
                   :loading="firstLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    // 用户管理内容
    firstData: {
      type: Array,
    },
  },
  data () {
    return {
      firstShow: false, //列表删除loading
      firstLoading: false, //提交登录
      first: false, //用户管理添加窗口
      // 用户管理添加的内容
      firstForm: {
        name: "",
      },
    };
  },

  mounted () { },

  methods: {
    // 用户管理添加
    handleFirstFirm () {
      if (this.firstForm.name == "") {
        this.$message({
          message: "请输入体系名称",
          type: "error",
        });
        return;
      }
      this.firstLoading = true;
      //   编辑
      if (this.firstForm.id) {
        this.$axios
          .put("/api/v6/ShopsSystem", {
            id: this.firstForm.id,
            name: this.firstForm.name,
          })
          .then((res) => {
            console.log(res);
            this.firstLoading = false;
            if (res.data.status == 0) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.first = false;
              this.firstForm = {
                name: "",
              };
              this.$emit("handleFirst");
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.firstLoading = false;
            this.firstForm = {
              name: "",
            };
            this.$message({
              message: error,
              type: "error",
            });
          });
      } else {
        this.$axios
          .post("/api/v6/ShopsSystem", {
            name: this.firstForm.name,
          })
          .then((res) => {
            console.log(res);
            this.firstLoading = false;
            if (res.data.status == 0) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.first = false;
              this.firstForm = {
                name: "",
              };
              this.$emit("handleFirst");
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.firstLoading = false;
            this.firstForm = {
              name: "",
            };
            this.$message({
              message: error,
              type: "error",
            });
          });
      }
    },
    // 编辑
    handleRedact (row) {
      let a = {
        id: row.id,
        name: row.name,
      };
      this.firstForm = a;
      this.first = true;
    },
    // 删除产品列表
    handlePopover (id) {
      this.firstShow = true;
      this.$axios
        .delete_params("/api/v6/ShopsSystem", { id })
        .then((res) => {
          this.firstShow = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$refs[`popover-${id}`].doClose();
            this.$emit("handleFirst");
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.firstShow = false;
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.system_box {
  .system_add {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    .system_add_item {
      color: $bg;
      cursor: pointer;
    }
  }
}
.scope_operat {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  .oper_box {
    color: #3978f7;
    text-align: center !important;
    cursor: pointer;
    padding: 3px 0;
  }
}
</style>
