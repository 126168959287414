<template>
  <div class="bost">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
    <el-form-item>
      <el-date-picker
        v-model="time"
        type="daterange"
        @change="handleDate"
        size="medium"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="TaskRecord" size="medium">查询</el-button>
    </el-form-item>
  </el-form>
        <!-- 表格 -->
    <el-table
      :data="tableData"
      v-loading="tableLoading"
      :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      style="width: 100%"
    >
      <el-table-column prop="id" label="编号" align="center" width="70">
      </el-table-column>
      <el-table-column
        prop="quantity"
        label="查询链接数量"
        align="center"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="money"
        align="center"
        min-width="100"
        label="消费金额（元）"
      >
      </el-table-column>
      <el-table-column
        prop="trade_date"
        align="center"
        min-width="100"
        label="时间"
      >
      </el-table-column>
      <el-table-column
        prop="brand"
        align="center"
        min-width="100"
        label="品牌"
      >
      </el-table-column>
      
    </el-table>

    <!-- 分页 -->
    <div class="page" v-if="pageLoad">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="recordForm.pageNum"
        :page-sizes="[30, 60, 120]"
        :page-size="recordForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkspaceJsonRecord",
  data() {
    return {
      recordForm: {
        strat_date:'',
        end_date:'',
        pageNum: 1,
        pageSize: 30
      },
      time:'',
      loading: false, //查询loading
      tableData: [],
      tableLoading: false, //列表loading

      total: 0, //总条数
      pageLoad: false,
    };
  },

  mounted() {
    this.TaskRecord();
  },

  methods: {
    // 日期选择
    handleDate(e) {
      if (e == null || e == undefined) {
        this.recordForm.strat_date = "";
        this.recordForm.end_date = "";
        return;
      }
      this.recordForm.strat_date = e[0];
      this.recordForm.end_date = e[1];
    },
    // 查询记录
    TaskRecord(firm) {
      this.loading = true;
      this.tableLoading = true;
      if (firm) {
        this.recordForm.pageNum = 1;
      }
      this.$axios
        .post("/api/v6/RpExpenseDaily", this.recordForm)
        .then((res) => {
          this.loading = false;
          this.tableLoading = false;
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            var row =  res.data.data;
            for(let i in row){
                row[i].money = this.abs(row[i].quantity*5);
            }
            this.tableData = row;
            this.pageLoad = true;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tableLoading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 价格格式化
    abs(val){
        var str = (val/100).toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
    },
    // 每页显示条数
    handleSizeChange(val) {
      if (val == this.recordForm.pageSize) return;
      this.recordForm.pageSize = val;
      this.TaskRecord();
    },
    // 当前页面
    handleCurrentChange(val) {
      if (val == this.recordForm.pageNum) return;
      this.recordForm.pageNum = val;
      this.TaskRecord();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>