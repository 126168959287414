export default {
  state: {
    token:'',
    user:null,
    tabsList: [
      {
        name: "数据看板",
        router: "/",
      },
    ],
    skuV1:{},
    sku:{},
  },
  mutations: {
    tabs(state, val) {
      state.tabsList.push({ name: val.name, router: val.router });
    },
    removeTabs(state,val) {
      state.tabsList = [val];
      console.log(val)
    },
    loginSuccess(state){
      state.token = '';
    },
    login(state,val){
      state.token = val;
    },
    user(state,val){
      state.user = val;
    },
    skuV1(state,val){
      state.skuV1 = val;
    },
    sku(state,val){
      state.sku = val;
    }
  },
  actions: {},
};
