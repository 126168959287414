<template>
  <div class="system">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="价格体系" name="first">
        <system-first
          :loading="loading4"
          :firstData="secondData"
          :spanArr="spanArr"
          :pos="pos"
          v-on:handleSecond="handleSecond"
        ></system-first>
      </el-tab-pane>
      <el-tab-pane label="店铺体系" name="second">
        <system-second
          :loading="loading"
          :firstData="firstData"
          v-on:handleFirst="handleFirst"
        ></system-second>
      </el-tab-pane>
      <el-tab-pane label="价格级别" name="third">
        <system-third
          :loading="loading1"
          :firstData="thirdData"
          v-on:handleThird="handleThird"
        ></system-third>
      </el-tab-pane>
      <el-tab-pane label="产品系列" name="fourth">
        <system-fourth
          :loading="loading2"
          :firstData="fourthData"
          v-on:handleFourth="handleFourth"
        ></system-fourth>
      </el-tab-pane>
      <el-tab-pane label="SKU名称" name="specification">
        <system-specif
          :loading="loading3"
          :firstData="specifData"
          v-on:handleSpecif="handleSpecif"
        ></system-specif>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// 价格体系
import systemFirst from "../../components/system/first";
// 店铺体系
import systemSecond from "../../components/system/second";
// 价格级别
import systemThird from "../../components/system/third";
// 产品系列
import systemFourth from "../../components/system/fourth";
// 产品规格
import systemSpecif from "../../components/system/specification";
import Aegis from "aegis-web-sdk";
export default {
  components: {
    systemSecond,
    systemThird,
    systemFourth,
    systemSpecif,
    systemFirst,
  },
  data() {
    return {
      // 切换内容
      activeName: "first",
      // 价格体系
      secondData: [],
      loading4: true,
      spanArr: [], //一个空数组，用于存放每一行记录的合并数
      pos: 0, //spanArr索引
      //   店铺体系内容
      firstData: [],
      loading: true, //店铺体系列表加载
      //   价格级别
      thirdData: [],
      loading1: true, //价格级别列表加载
      //   产品体系
      fourthData: [],
      loading2: true, //价格级别列表加载
      //   产品规格
      specifData: [],
      loading3: true, //价格级别列表加载
    };
  },
  created() {
    const aegis = new Aegis({
      id: "XaKVn5BzYaR3MRyjbO", // 上报 id
      uin: "xxx", // 用户唯一 ID（可选）
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
    });
    aegis.infoAll("上传");
    this.handleSecond();
  },
  methods: {
    //   切换
    handleClick(tab, event) {
      //   切换加载内容
      console.log(tab, event);
      if (tab.name == "first") {
        this.handleSecond();
      }
      //   加载店铺体系
      if (tab.name == "second" && this.firstData.length == 0) {
        this.handleFirst();
      }
      if (tab.name == "third" && this.thirdData.length == 0) {
        this.handleThird();
      }
      if (tab.name == "fourth" && this.fourthData.length == 0) {
        this.handleFourth();
      }
      if (tab.name == "specification" && this.specifData.length == 0) {
        this.handleSpecif();
      }
    },
    // 价格体系
    handleSecond() {
      this.loading4 = true;
      this.$axios
        .get("/api/PricesSystem", {})
        .then((res) => {
          this.loading4 = false;
          if (res.data.status == 0) {
            this.secondData = res.data.data;
            this.spanArr = [];
            for (var i = 0; i < res.data.data.length; i++) {
              if (i === 0) {
                this.spanArr.push(1);
                this.pos = 0;
              } else {
                if (
                  res.data.data[i].tb_products_skus_id ===
                  res.data.data[i - 1].tb_products_skus_id
                ) {
                  this.spanArr[this.pos] += 1;
                  this.spanArr.push(0);
                } else {
                  this.spanArr.push(1);
                  this.pos = i;
                }
              }
            }
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loading4 = false;
          console.log(error);
        });
    },
    // 店铺体系内容
    handleFirst() {
      this.loading = true;
      this.$axios
        .get("/api/v6/ShopsSystem", {})
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            this.firstData = res.data.data;
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    // 价格级别内容
    handleThird() {
      this.loading1 = true;
      this.$axios
        .get("/api/v6/PricesLevel", {})
        .then((res) => {
          this.loading1 = false;
          if (res.data.status == 0) {
            this.thirdData = res.data.data;
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loading1 = false;
          console.log(error);
        });
    },
    // 产品系列内容
    handleFourth() {
      this.loading2 = true;
      this.$axios
        .get("/api/ProductsSeries", {})
        .then((res) => {
          this.loading2 = false;
          if (res.data.status == 0) {
            this.fourthData = res.data.data;
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loading2 = false;
          console.log(error);
        });
    },
    // 产品规格
    handleSpecif() {
      this.loading3 = true;
      this.$axios
        .get("/api/v6/ProductsSkus", {})
        .then((res) => {
          this.loading3 = false;
          if (res.data.status == 0) {
            this.specifData = res.data.data;
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loading3 = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.system {
  width: 100%;
  min-width: 1392px;
  min-height: 500px;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 1110px;
  }
}
</style>
