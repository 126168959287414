import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./request/http";
import "./assets/scss/reset.scss";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueWechatTitle from 'vue-wechat-title';
import JsonExcel from 'vue-json-excel';

// https://hooray.github.io/vue-sku-form/guide/
// import SkuForm from 'vue-sku-form';
// Vue.use(SkuForm)
Vue.component('downloadExcel', JsonExcel);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueWechatTitle);
Vue.prototype.$axios = api;
router.beforeEach((to, from, next) => {
  console.log(to);
  if(to.matched[0].path == '/loginForgot' || to.matched[0].path == '/UpdatePassword'){
    next();
  }else if (to.matched.some((record) => record.meta.requireAuth)) {
    
    if (localStorage.token) {
      if (to.meta.title) {
        document.title = to.meta.title;
      }
      next();
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  api,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
