<template>
  <!-- 产品系列 -->
  <div>
    <div class="system_box" style="width: 800px">
      <div class="system_add">
        <div class="system_add_item" @click="handleOpen">添加</div>
      </div>
      <el-table
        v-loading="loading"
        :data="firstData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      >
        <el-table-column prop="series" label="系列" align="center">
        </el-table-column>
        <el-table-column prop="name" label="SKU名称" align="center">
        </el-table-column>
        <el-table-column prop="alias" label="别名" align="center">
        </el-table-column>
        <el-table-column prop="expression" label="正则" align="center">
        </el-table-column>
        <el-table-column prop="note" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="logo" label="图片" align="center">
          <template slot-scope="scope">
            <div
              class="scope_box"
              style="cursor: pointer"
              @click="handleClick(scope.row.url)"
              v-if="scope.row.logo != ''"
            >
              <el-popover placement="right" width="350" trigger="hover">
                <el-image
                  style="width: 100%; height: auto"
                  :src="scope.row.logo"
                  fit="fill"
                ></el-image>
                <el-image
                  slot="reference"
                  style="width: 48px; height: 48px"
                  :src="scope.row.logo"
                  fit="fill"
                ></el-image>
              </el-popover>
            </div>
            <div v-else>未上传</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <div class="scope_operat">
              <div class="oper_box" @click="handleRedact(scope.row)">编辑</div>
              <el-popover
                placement="left"
                width="160"
                :ref="`popover2-${scope.row.id}`"
              >
                <p style="text-align: center">是否删除产品？</p>
                <div style="text-align: right; margin-top: 10px">
                  <el-button
                    size="mini"
                    type="text"
                    @click="
                      scope._self.$refs[`popover2-${scope.row.id}`].doClose()
                    "
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="handlePopover(scope.row.id)"
                    :loading="firstShow"
                    >确定</el-button
                  >
                </div>
                <div
                  class="oper_box"
                  style="width: 100%; text-align: right"
                  slot="reference"
                >
                  删除
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="first" width="500px" @close="handleCloses1">
      <el-form
        :model="firstForm"
        :rules="ActiveRules"
        ref="ruleActive"
        label-width="80px"
      >
        <el-form-item label="产品系列" prop="series_id">
          <el-select v-model="firstForm.series_id" placeholder="请选择产品系列">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="SKU名称" prop="name">
          <el-input
            v-model="firstForm.name"
            autocomplete="off"
            placeholder="请输入SKU名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="别名" prop="alias">
          <el-input
            v-model="firstForm.alias"
            autocomplete="off"
            type="textarea"
            :rows="3"
            placeholder="与=&，或=|，例如：iphone 13 pluls 表达式： iphone&13&plus|iphone&13&plus&黑|iphone&13&plus&白"
          ></el-input>
        </el-form-item>
        <el-form-item label="正则" prop="expression">
          <el-input
            v-model="firstForm.expression"
            autocomplete="off"
            type="textarea"
            :rows="3"
            placeholder="正则表达式"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input
            v-model="firstForm.note"
            autocomplete="off"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="firstDic">
        <p>匹配优先级：正则表达式、别名、sku名称</p>
        <p>匹配数据：spu名称+sku描述</p>
        <p>例如：</p>
        <p>spu名称：屈臣氏苏打水干姜汤力水香草味盐味柠檬草味330ml*24罐整箱气泡水</p>
        <p>sku描述：口味:汤力水330ml*8听</p>
        <p>匹配内容：屈臣氏苏打水干姜汤力水香草味盐味柠檬草味330ml*24罐整箱气泡水口味:汤力水330ml*8听</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="first = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleFirstFirm('ruleActive')"
          :loading="firstLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    // 内容
    firstData: {
      type: Array,
    },
  },
  data() {
    return {
      firstShow: false, //列表删除loading
      firstLoading: false, //提交登录
      first: false, //添加窗口
      // 添加的内容
      firstForm: {
        name: "",
        series_id: "",
        logo: "",
        alias: "",
        note: "",
        expression:''
      },
      //   产品系列
      options: [],
      ActiveRules: {
        name: [{ required: true, message: "请输入SKU名称", trigger: "blur" }],
        series_id: [
          { required: true, message: "请选择产品系列", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {},

  methods: {
    //   打开窗口
    handleOpen() {
      this.first = true;
      this.handleFourth();
    },
    // 产品系列内容
    handleFourth() {
      this.$axios
        .get("/api/ProductsSeries", {})
        .then((res) => {
          if (res.data.status == 0) {
            this.options = [];
            for (let i in res.data.data) {
              this.options.push({
                value: res.data.data[i].id,
                label: res.data.data[i].name,
              });
            }
          } else {
            this.$message({
              message: "加载产品系列错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 添加
    handleFirstFirm(ruleActive) {
      this.$refs[ruleActive].validate((valid) => {
        if (valid) {
          this.firstLoading = true;

          //   编辑
          if (this.firstForm.id) {
            this.$axios
              .put("/api/v6/ProductsSkus", {
                id: this.firstForm.id,
                name: this.firstForm.name,
                series_id: this.firstForm.series_id,
                logo: this.firstForm.logo,
                alias: this.firstForm.alias,
                note: this.firstForm.note == null ? '' : this.firstForm.note,
                expression:this.firstForm.expression,
              })
              .then((res) => {
                console.log(res);
                this.firstLoading = false;
                if (res.data.status == 0) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });
                  this.first = false;
                  this.firstForm = {
                    name: "",
                    series_id: "",
                    logo: "",
                  };
                  this.$emit("handleSpecif");
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                this.firstLoading = false;
                this.firstForm = {
                  name: "",
                  series_id: "",
                  logo: "",
                };
                this.$message({
                  message: error,
                  type: "error",
                });
              });
          } else {
            /*

检查名称是否存在

*/

            let idx = this.firstData.findIndex(
              (item) => item.name == this.firstForm.name
            );
            if (idx >= 0) {
              this.firstLoading = false;
              this.$message({
                message: "已存在相同SKU名称",
                type: "error",
              });
              return;
            }

            this.$axios
              .post("/api/v6/ProductsSkus", {
                name: this.firstForm.name,
                series_id: this.firstForm.series_id,
                logo: this.firstForm.logo,
                alias: this.firstForm.alias,
                note: this.firstForm.note,
                expression:this.firstForm.expression
              })
              .then((res) => {
                console.log(res);
                this.firstLoading = false;
                if (res.data.status == 0) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });
                  this.first = false;
                  this.firstForm = {
                    name: "",
                    series_id: "",
                    logo: "",
                    alias: "",
                    note: "",
                    expression:""
                  };
                  this.$emit("handleSpecif");
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                this.firstLoading = false;
                this.firstForm = {
                  name: "",
                  series_id: "",
                  logo: "",
                  alias: "",
                  note: "",
                  expression:""
                };
                this.$message({
                  message: error,
                  type: "error",
                });
              });
          }
        } else {
          console.log("error submit!!");
          return;
        }
      });
    },
    // 取消/关闭窗口
    handleCloses1() {
      // 初始化
      let a = {
        name: "",
        series_id: "",
        logo: "",
        alias: "",
        note: "",
        expression:""
      };
      this.firstForm = a;
      this.$refs.ruleActive.resetFields();
    },
    // 编辑
    handleRedact(row) {
      let a = {
        id: row.id,
        name: row.name,
        series_id: row.series_id,
        logo: row.logo == null ? "" : row.logo,
        alias: row.alias,
        note: row.note,
        expression:row.expression == null ? "" : row.expression
      };
      this.firstForm = a;
      this.first = true;
      this.handleFourth();
    },
    // 删除产品列表
    handlePopover(id) {
      this.firstShow = true;
      this.$axios
        .delete_params("/api/v6/ProductsSkus", { id })
        .then((res) => {
          this.firstShow = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$refs[`popover2-${id}`].doClose();
            this.$emit("handleSpecif");
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.firstShow = false;
          this.$message({
            message: error,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.system_box {
  .system_add {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    .system_add_item {
      color: $bg;
      cursor: pointer;
    }
  }
}
.scope_operat {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  .oper_box {
    color: #3978f7;
    text-align: center !important;
    cursor: pointer;
    padding: 3px 0;
  }
}
.firstDic p{
  line-height: 1.6;
}
</style>
