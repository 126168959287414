<template>
  <div class="bost">
    <!-- 查询 -->
    <el-form
      ref="inquires"
      :inline="true"
      :model="recordForm"
      label-width="80px"
      size="small"
    >
      <el-form-item>
        <el-input
          v-model="recordForm.schedule_name"
          placeholder="请输入任务名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="recordForm.start_at"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          size="small"
          placeholder="请选择开始时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="recordForm.end_at"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          size="small"
          placeholder="请选择结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="TaskRecord('firm')"
          size="small"
          :loading="loading"
          >查询</el-button
        >
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table
      :data="tableData"
      v-loading="tableLoading"
      :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      style="width: 100%"
    >
      <el-table-column prop="id" label="编号" align="center" width="70">
      </el-table-column>
      <el-table-column
        prop="schedule_name"
        label="任务名称"
        align="center"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="quantity"
        align="center"
        min-width="100"
        label="总数量"
      >
        <template slot-scope="scope">
          {{ scope.row.quantity }}/{{ scope.row.quantity_complete }}
        </template>
      </el-table-column>
      <el-table-column
        prop="taobao_quantity"
        align="center"
        min-width="100"
        label="淘宝"
      >
        <template slot-scope="scope">
          {{ scope.row.taobao_quantity }}/{{
            scope.row.taobao_quantity_complete
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="tmall_quantity"
        align="center"
        min-width="100"
        label="天猫"
      >
        <template slot-scope="scope">
          {{ scope.row.tmall_quantity }}/{{ scope.row.tmall_quantity_complete }}
        </template>
      </el-table-column>
      <el-table-column
        prop="jd_quantity"
        align="center"
        min-width="100"
        label="京东"
      >
        <template slot-scope="scope">
          {{ scope.row.jd_quantity }}/{{ scope.row.jd_quantity_complete }}
        </template>
      </el-table-column>
      <el-table-column
        prop="pinduoduo_quantity"
        align="center"
        min-width="100"
        label="拼多多"
      >
        <template slot-scope="scope">
          {{ scope.row.pinduoduo_quantity }}/{{
            scope.row.pinduoduo_quantity_complete
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="douyin_quantity"
        align="center"
        min-width="100"
        label="抖音"
      >
        <template slot-scope="scope">
          {{ scope.row.douyin_quantity }}/{{
            scope.row.douyin_quantity_complete
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="kuaishou_quantity"
        align="center"
        min-width="100"
        label="快手"
      >
        <template slot-scope="scope">
          {{ scope.row.kuaishou_quantity }}/{{
            scope.row.kuaishou_quantity_complete
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="xianyu_quantity"
        align="center"
        min-width="100"
        label="闲鱼"
      >
        <template slot-scope="scope">
          {{ scope.row.xianyu_quantity }}/{{
            scope.row.xianyu_quantity_complete
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="a1688_quantity"
        align="center"
        min-width="100"
        label="1688"
      >
        <template slot-scope="scope">
          {{ scope.row.a1688_quantity }}/{{ scope.row.a1688_quantity_complete }}
        </template>
      </el-table-column>
      <el-table-column
        prop="aliexpress_quantity"
        align="center"
        min-width="100"
        label="速卖通"
      >
        <template slot-scope="scope">
          {{ scope.row.aliexpress_quantity }}/{{
            scope.row.aliexpress_quantity_complete
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="vip_quantity"
        align="center"
        min-width="100"
        label="唯品会"
      >
        <template slot-scope="scope">
          {{ scope.row.vip_quantity }}/{{ scope.row.vip_quantity_complete }}
        </template>
      </el-table-column>
      <el-table-column
        prop="start_at"
        align="center"
        min-width="150"
        label="开始时间"
      >
      </el-table-column>
      <el-table-column
        prop="end_at"
        align="center"
        min-width="150"
        label="结束时间"
      >
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="page" v-if="pageLoad">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="recordForm.pageNum"
        :page-sizes="[30, 60, 120]"
        :page-size="recordForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkspaceJsonRecord",
  data() {
    return {
      recordForm: {
        pageNum: 1,
        pageSize: 30,
        schedule_name: "", //任务名称
        start_at: "", //开始时间
        end_at: "", //结束时间
      },
      loading: false, //查询loading
      tableData: [],
      tableLoading: false, //列表loading

      total: 0, //总条数
      pageLoad: false,
    };
  },

  mounted() {
    this.TaskRecord();
  },

  methods: {
    // 查询记录
    TaskRecord(firm) {
      this.loading = true;
      this.tableLoading = true;
      if (firm) {
        this.recordForm.pageNum = 1;
      }
      this.$axios
        .post("/api/v2/TaskRecord", this.recordForm)
        .then((res) => {
          this.loading = false;
          this.tableLoading = false;
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            this.tableData = res.data.data;
            this.pageLoad = true;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tableLoading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 每页显示条数
    handleSizeChange(val) {
      if (val == this.recordForm.pageSize) return;
      this.recordForm.pageSize = val;
      this.TaskRecord();
    },
    // 当前页面
    handleCurrentChange(val) {
      if (val == this.recordForm.pageNum) return;
      this.recordForm.pageNum = val;
      this.TaskRecord();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>