<template>
  <div class="after">
    <div class="after_top">
      <el-button
        type="primary"
        class="print"
        size="small"
        icon="el-icon-printer"
        >打印</el-button
      >
      <el-table
        v-loading="itemLoading"
        :data="tableItem"
        style="width: 100%"
        :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      >
        <el-table-column prop="date" label="宝贝名称" align="center">
          <template slot-scope="scope">
            <div
              class="scope_box"
              style="cursor: pointer"
              @click="handleClick(scope.row.url)"
            >
              <el-popover placement="right" width="350" trigger="hover">
                <el-image
                  style="width: 100%; height: auto"
                  :src="scope.row.pic_url"
                  fit="fill"
                ></el-image>
                <el-image
                  slot="reference"
                  style="width: 48px; height: 48px"
                  :src="scope.row.pic_url"
                  fit="fill"
                ></el-image>
              </el-popover>
              <span>{{ scope.row.title }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="platform_name" label="平台" align="center">
        </el-table-column>
        <el-table-column prop="device" label="端" align="center">
        </el-table-column>
        <el-table-column prop="start_date" label="收录日期" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="after_cent">
      <p class="after_p">移动端改价跟踪</p>
      <el-table
        v-loading="listLoading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#fafafa', color: '#333333' }"
      >
        <el-table-column prop="check_time" label="日期" align="center">
        </el-table-column>
        <el-table-column prop="title" label="SKU" align="center" width="280">
          <template slot-scope="scope">
            <div
              class="scope_box"
              style="cursor: pointer"
              @click="handleClick(scope.row.shop_url)"
            >
              <el-popover placement="right" width="350" trigger="hover">
                <el-image
                  style="width: 100%; height: auto"
                  :src="scope.row.sku_pic_url"
                  fit="fill"
                ></el-image>
                <el-image
                  slot="reference"
                  style="width: 48px; height: 48px"
                  :src="scope.row.sku_pic_url"
                  fit="fill"
                ></el-image>
              </el-popover>
              <span>{{ scope.row.skus_title }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="original_price" label="变动前" align="center">
        </el-table-column>
        <el-table-column prop="current_price" label="变动后" align="center">
        </el-table-column>
      </el-table>
      <div class="pages" v-if="pageLoad">
        <el-pagination
          background
          :hide-on-single-page="true"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-sizes="[30, 60, 120]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null, //接收的ID
      itemLoading: true,
      tableItem: [], //产品详情
      listLoading: true, //列表加载
      pageLoad: false, //分页显示
      tableData: [], //改价跟踪
      pageNum: 1, //目前第几页
      pageSize: 30, //默认每页30条
      total: 0, //总条数
    };
  },
  created() {
    this.id = this.$route.params.id;
    // 产品详情
    this.itemData(this.id);
    // 改价跟踪
    this.itemList(this.id);
  },
  methods: {
    // 产品详情
    itemData(id) {
      this.$axios
        .get("/api/Goods?id=" + id)
        .then((res) => {
          this.itemLoading = false;
          if (res.data.status == 0) {
            this.tableItem.push(res.data.data);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {
          this.itemLoading = false;
          this.$message.error("加载错误");
        });
    },
    // 商品url跳转
    handleClick(url) {
      window.open(url);
    },
    // 改价跟踪
    itemList(id) {
      this.listLoading = true;
      this.$axios
        .post("/api/ChangeRecord", {
          goods_id: parseInt(id),
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.status == 0) {
            this.total = res.data.bag.totalRows;
            this.tableData = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
          this.listLoading = false;
          this.pageLoad = true;
        })
        .catch(() => {
          this.listLoading = false;
          this.$message.error("加载错误");
        });
    },
    // 每页显示条数
    handleSizeChange(val) {
      if (val == this.pageSize) return;
      this.pageSize = val;
      this.itemList();
    },
    // 当前页面
    handleCurrentChange(val) {
      if (val == this.pageNum) return;
      this.pageNum = val;
      this.itemList();
    },
  },
};
</script>

<style lang="scss" scoped>
.after {
  width: 100%;
  min-width: 1392px;
  @media screen and (max-width: 1400px) {
    min-width: 1000px;
  }
}
.scope_box {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 8px;
    color: #3978f7;
    &:hover {
      text-decoration: underline;
    }
  }
}
.after_top {
  width: 100%;
  padding: 24px 32px;
  background-color: #ffffff;
  .print {
    background-color: $bg;
    color: #ffffff;
    width: 80px;
    margin-bottom: 24px;
  }
}
.after_cent {
  width: 100%;
  padding: 24px 32px;
  background-color: #ffffff;
  margin-top: 24px;
  .after_p {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 24px;
  }
}
.pages {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
</style>
