<template>
  <div class="remind">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="查询记录" name="record">
        <inquire-record></inquire-record>
      </el-tab-pane>
      <el-tab-pane label="消费统计" name="consumption">
        <consu-mption></consu-mption>
      </el-tab-pane>
      <el-tab-pane label="查询任务" name="second">
        <div class="task_top">
          <el-button
            size="small"
            type="primary"
            class="prim1"
            icon="el-icon-circle-plus"
            @click="handleAdd"
            >添加任务</el-button
          >
        </div>
        <el-table
          v-loading="loading"
          :data="tableItem"
          style="width: 100%"
          :header-cell-style="{ background: '#fafafa', color: '#333333' }"
        >
          <el-table-column prop="id" label="任务ID" align="center" min-width="70">
          </el-table-column>
          <el-table-column prop="name" label="任务名称" align="center" min-width="100">
          </el-table-column>
          <el-table-column label="查询周期" align="center" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.week | formatWeek }}
            </template>
          </el-table-column>
          <el-table-column prop="hour" label="查询时间" align="center" min-width="150">
          </el-table-column>
          <el-table-column
            prop="update_time"
            label="更新时间"
            align="center"
            min-width="180"
          >
          </el-table-column>
          <el-table-column prop="scheme" label="计算方式" align="center" min-width="180">
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
            align="center"
            min-width="180"
          >
          </el-table-column>
          <el-table-column
            prop="use_switch"
            label="查询状态"
            align="center"
            min-width="180"
          >
            <template slot-scope="scope">
              <el-switch
                @change="elSwitch($event, scope.row.id)"
                v-model="scope.row.use_switch"
                inactive-text="关"
                active-text="开"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="call" label="预警通知" align="center" min-width="180">
            <template slot-scope="scope">
              <el-switch
                @change="callSwitch($event, scope.row.id)"
                v-model="scope.row.call"
                inactive-text="关"
                active-text="开"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="call_mail"
            label="预警通知邮箱"
            align="center"
            min-width="180"
          >
            <template slot-scope="scope">
              <p
                v-if="scope.row.call_mail == null"
                @click="openCallmail(scope.row.id, scope.row.call_mail)"
                style="color: #3978f7; cursor: pointer"
              >
                设置
              </p>
              <p
                v-else
                style="cursor: pointer"
                @click="openCallmail(scope.row.id, scope.row.call_mail)"
              >
                {{ scope.row.call_mail }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="name_call"
            label="SKU规格变化通知"
            align="center"
            min-width="180"
          >
            <template slot-scope="scope">
              <el-switch
                @change="nameCallSwitch($event, scope.row.id)"
                v-model="scope.row.name_call"
                inactive-text="关"
                active-text="开"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="call_name_mail"
            label="SKU规格变化通知邮箱"
            align="center"
            min-width="180"
          >
            <template slot-scope="scope">
              <p
                v-if="scope.row.call_name_mail == null"
                @click="openCallNameMail(scope.row.id, scope.row.call_name_mail)"
                style="color: #3978f7; cursor: pointer"
              >
                设置
              </p>
              <p
                v-else
                style="cursor: pointer"
                @click="openCallNameMail(scope.row.id, scope.row.call_name_mail)"
              >
                {{ scope.row.call_name_mail }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            align="center"
            width="200"
            fixed="right"
          >
            <template slot-scope="scope">
              <div class="scope_operat">
                <div class="oper_box" @click="Immediately(scope.$index, scope.row)">
                  立即执行
                </div>
                <div class="oper_box" @click="handleGenx(scope.$index, scope.row)">
                  更新
                </div>
                <el-popover placement="left" width="80" :ref="`popover-${scope.row.id}`">
                  <p style="text-align: center">是否删除产品？</p>
                  <div style="text-align: right; margin-top: 10px">
                    <el-button
                      size="mini"
                      type="text"
                      @click="scope._self.$refs[`popover-${scope.row.id}`].doClose()"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="handlePopover(scope.row.id)"
                      :loading="vsbtn"
                      >确定</el-button
                    >
                  </div>
                  <div
                    class="oper_box"
                    style="width: 100%; text-align: center"
                    slot="reference"
                  >
                    删除
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Aegis from "aegis-web-sdk";
import inquireRecord from "../../components/Inquire/record";
import consuMption from "../../components/consumption/consumption.vue";
export default {
  components: {
    inquireRecord,
    consuMption,
  },
  data() {
    return {
      activeName: "record",
      loading: true,
      pageLoad: false,
      tableItem: [],
      vsbtn: false, //删除loading
    };
  },
  created() {
    const aegis = new Aegis({
      id: "XaKVn5BzYaR3MRyjbO", // 上报 id
      uin: "xxx", // 用户唯一 ID（可选）
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
    });
    aegis.infoAll("上传");
    this.loadSchedule();
  },
  filters: {
    formatWeek: function (value) {
      if (!value) return "";
      value = value.replace(/0/, "星期日");
      value = value.replace(/1/, "星期一");
      value = value.replace(/2/, "星期二");
      value = value.replace(/3/, "星期三");
      value = value.replace(/4/, "星期四");
      value = value.replace(/5/, "星期五");
      value = value.replace(/6/, "星期六");
      return value;
    },
  },
  methods: {
    // 状态开关
    elSwitch(e, b) {
      let use = e ? "开" : "关";
      this.$axios
        .put(`/api/v2/ScheduleSwitch?id=${b}&use_switch=${use}`, {})
        .then((res) => {
          console.log(res);
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    // 预警通知邮箱
    openCallmail(id, mail) {
      const h = this.$createElement;
      this.$prompt("请输入预警邮箱", "预警邮箱设置", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: mail,
        message: h("p", null, [
          h("h2", null, "预警邮箱须知： "),
          h("p", { style: "color: #333" }, "1：打开预警通知"),
          h(
            "p",
            { style: "color: #333" },
            "2：设置预警通知邮箱：通知范围：用户邮箱+预警通知邮箱"
          ),
          h("p", { style: "color: #333" }, "3：未设置预警通知邮箱：通知范围：用户邮箱"),
          h(
            "p",
            { style: "color: #333" },
            "4：多个邮箱用逗号隔开（例：11@qq.com,22@qq.com）"
          ),
        ]),
      }).then(({ value }) => {
        this.ScheduleCallMail(id, value);
      });
    },
    // 预警通知邮箱提交设置
    ScheduleCallMail(id, mail) {
      this.$axios
        .put(`/api/v6/ScheduleCallMail?id=${id}&call_mail=${mail}`, {})
        .then((res) => {
          if (res.data.status == 0) {
            this.loadSchedule();
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    // SKU规格变化通知邮箱设置
    openCallNameMail(id, mail) {
      const h = this.$createElement;
      this.$prompt("请输入通知邮箱", "SKU规格变化通知邮箱设置", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: mail,
        message: h("p", null, [
          h("h2", null, "通知邮箱须知： "),
          h("p", { style: "color: #333" }, "1：打开SKU规格变化通知"),
          h(
            "p",
            { style: "color: #333" },
            "2：设置SKU规格变化通知邮箱：通知范围：用户邮箱+通知邮箱"
          ),
          h(
            "p",
            { style: "color: #333" },
            "3：未设置SKU规格变化通知邮箱：通知范围：用户邮箱"
          ),
          h(
            "p",
            { style: "color: #333" },
            "4：多个邮箱用逗号隔开（例：11@qq.com,22@qq.com）"
          ),
        ]),
      }).then(({ value }) => {
        this.ScheduleCallNameMail(id, value);
      });
    },
    // SKU规格变化通知邮箱提交设置
    ScheduleCallNameMail(id, mail) {
      this.$axios
        .put(`/api/v6/ScheduleNameChangeCallMail?id=${id}&call_mail=${mail}`, {})
        .then((res) => {
          if (res.data.status == 0) {
            this.loadSchedule();
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    nameCallSwitch(e, b) {
      let use = e ? "开" : "关";
      console.log(use, b, e);
      this.$axios
        .put(`/api/v6/ScheduleNameChangeCall?id=${b}&call=${use}`, {})
        .then((res) => {
          console.log(res);
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    // 预警通知
    callSwitch(e, b) {
      let use = e ? "开" : "关";
      this.$axios.put(`/api/v2/ScheduleCall?id=${b}&call=${use}`, {}).then((res) => {
        console.log(res);
        if (res.data.status == 0) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    // 切换
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 获取数据；
    loadSchedule() {
      this.loading = true;
      this.$axios
        .get("/api/v6/Schedule", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.status == 0) {
            let as = res.data.data;
            for (let i = 0; i < as.length; i++) {
              if (as[i].use_switch == null || as[i].use_switch == "开") {
                as[i].use_switch = true;
              } else {
                as[i].use_switch = false;
              }
              as[i].call = as[i].call === "开" ? true : false;
              as[i].name_call =
                as[i].name_call == "关" || as[i].name_call == null ? false : true;
            }
            this.tableItem = as;
          }
          this.loading = false;
        });
    },

    // 删除按钮 确定
    handlePopover(id) {
      this.vsbtn = true;
      this.$axios
        .delete_params("/api/Schedule", { id })
        .then((res) => {
          this.vsbtn = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$refs[`popover-${id}`].doClose();
            this.loadSchedule();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.vsbtn = false;
          console.log(err);
        });
    },
    // 新增
    handleAdd() {
      this.$router.push({ name: "addTask" });
    },
    handleGenx(index, row) {
      this.$router.push({ name: "addTask", params: { data: row } });
    },
    // 立即执行
    Immediately(index, row) {
      console.log(index);
      console.log(row);

      this.$confirm("是否立即执行任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .get(`/api/v6/ScheduleStart?id=${row.id}`)
          .then((res) => {
            if (res.data.statusCode == 200) {
              this.$message({
                message: "任务执行成功",
                type: "success",
              });
            } else {
              this.$message({
                message: "任务执行失败！",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.vsbtn = false;
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.remind {
  width: 100%;
  min-width: 1392px;
  min-height: 100%;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 1000px;
  }
  .task_top {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .scope_box {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 8px;
      color: #3978f7;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .scope_box1 {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 8px;
    }
  }
}
.page {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.scope_operat {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .oper_box {
    padding: 2px 5px;
    color: #3978f7;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
