<template>
  <el-row>
    <el-col :span="24">
      <el-descriptions class="user_info" title="用户信息">
        <el-descriptions-item label="账号ID">{{
          user.id
        }}</el-descriptions-item>
        <el-descriptions-item label="公司">{{
          user.company
        }}</el-descriptions-item>
        <el-descriptions-item label="用户名">{{
          user.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{
          user.email
        }}</el-descriptions-item>
        <el-descriptions-item label="注册时间">
          {{ user.registration_time }}
        </el-descriptions-item>
        <el-descriptions-item label="到期时间">{{
          user.expiration_time
        }}</el-descriptions-item>
        <el-descriptions-item label="品牌">{{
          user.brand
        }}</el-descriptions-item>
        <el-descriptions-item label="账号类型">{{
          user.role
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="user_info" title="费用">
        <el-descriptions-item label="剩余金额（分）">{{
          user.b_shengyu
        }}</el-descriptions-item>
        <el-descriptions-item label="消费总数量（分）">{{
          user.b_xiaofei
        }}</el-descriptions-item>
        <el-descriptions-item label="充值总数量（分）">{{
          user.b_chonɡzhi
        }}</el-descriptions-item>
        <el-descriptions-item label="版本号">1.7</el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>
  <!-- <div class="user">

</div> -->
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      textarea: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.user {
  width: 100%;
  min-width: 1392px;
  @media screen and (max-width: 1400px) {
    min-width: 1000px;
  }
}
.user_info {
  width: 100%;
  min-height: 100px;
  padding: 24px 32px;
  background-color: #ffffff;
  .user_info_title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .user_info_left {
    font-size: 14px;
    color: $text_color;
  }
  .user_info_right {
    font-size: 14px;
    color: #666666;
  }
}
.opinion {
  width: 100%;
  min-height: 300px;
  padding: 24px 32px;
  background-color: #ffffff;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  .opinion_title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .submits {
    background-color: $bg;
    color: #ffffff;
    width: 100px;
    margin-top: 24px;
  }
}
</style>
