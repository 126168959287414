<template>
  <div>
    <el-button
        type="text"
        @click="GoodsFile = true"
        >价格体系上传</el-button
      >
    <el-table v-loading="loading"
              :data="firstData"
              :span-method="objectSpanMethod"
              :header-cell-style="{ background: '#fafafa', color: '#333333' }"
              style="width: 100%">
      <el-table-column prop="products_skus_series"
                       label="系列"
                       align="center"
                       min-width="80">
      </el-table-column>
      <el-table-column prop="products_skus_logo"
                       label="图片"
                       align="center"
                       width="100">
        <template slot-scope="scope">
          <div class="scope_box"
               style="cursor: pointer"
               v-if="scope.row.products_skus_logo != null">
            <el-popover placement="right"
                        width="350"
                        trigger="hover">
              <el-image style="width: 100%; height: auto"
                        :src="scope.row.products_skus_logo"
                        fit="fill"></el-image>
              <el-image slot="reference"
                        style="width: 48px; height: 48px"
                        :src="scope.row.products_skus_logo"
                        fit="fill"></el-image>
            </el-popover>
          </div>
          <div>未上传</div>
        </template>
      </el-table-column>
      <el-table-column prop="products_skus_name"
                       align="center"
                       label="SKU名称">
      </el-table-column>
      <el-table-column prop="alias"
                       align="center"
                       label="别名">
      </el-table-column>
      <el-table-column prop="note"
                       align="center"
                       label="备注">
      </el-table-column>
      <el-table-column prop="shops_system_name"
                       align="center"
                       label="店铺体系">
      </el-table-column>
      <el-table-column prop="prices_level_name"
                       align="center"
                       label="价格级别"
                       min-width="80">
      </el-table-column>
      <el-table-column prop="price"
                       align="center"
                       label="价格（元）"
                       width="130">
        <template slot-scope="scope">
          <input type="number" v-model.lazy="scope.row.price" class="el-input__inner">
        </template>
      </el-table-column>
      <el-table-column prop="start_time"
                       align="center"
                       label="开始执行时间"
                       min-width="100">
      </el-table-column>
      <el-table-column prop="end_time"
                       align="center"
                       label="结束执行时间"
                       min-width="100">
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-button type="primary"
                 class="prim"
                 @click="handleChange"
                 :loading="btnLoading">保存</el-button>
    </div>

    <el-dialog
      title="上传价格体系"
      :visible.sync="GoodsFile"
      @close="GoodsFile = false"
      width="450px"
    >
      <div class="GoodsFiles">
        <div class="GoodsFiles_Top_left">
          <!-- :on-success="upLoadImg"
            :on-error="errorImg" -->
          <el-upload
            class="upload-demo"
            ref="upload"
            :limit="1"
            :file-list="uploadFile"
            action="action"
            :multiple="false"
            :auto-upload="false"
            :http-request="allUpLoad"
            accept=".xls,.xlsx"
            :on-change="handleChangeXls"
            :on-remove="handleRemove"
          >
            <el-button slot="trigger" type="primary">选择文件</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传xls/xlsx文件，且不超过3M
            </div>
            <div slot="tip" class="el-upload__tip" style="color:#3a8ee6;font-weight: bold;">
              上传完成之后需要刷页面
            </div>
          </el-upload>
        </div>
        <div class="GoodsFiles_Top_right">
          <p>
            上传格式模板：
            <a class="upLoad_Modules" :href="hrefs" target="_blank">下载模板</a>
          </p>
          <p>上传采用异步无需等待，结果会以邮件的形式通知到你</p>
          <p>
            <b>{{ $store.state.tab.user.email }}</b>
          </p>
        </div>
        <div class="GoodsFiles_footer">
          <el-button
            type="text"
            size="small"
            style="margin-right: 15px"
            @click="GoodsFile = false"
            >取消</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            @click="submitUpload"
            :loading="fileLoad"
            >上传到服务器</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    // 内容
    firstData: {
      type: Array,
    },
    spanArr: {
      type: Array,
    },
    pos: {
      type: Number,
    },
  },
  data () {
    return {
      btnLoading: false,
      GoodsFile: false, //打开导入文件窗口
      uploadFile: [],
      hrefs:
        "https://datamonitorshop.oss-cn-hangzhou.aliyuncs.com/templet/%E4%BB%B7%E6%A0%BC%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx", //下载地址
      fileLoad: false,
    };
  },

  computed: {
    datas: function () {
      return this.firstData;
    },
  },

  methods: {
    // 设置合并行row, column, rowIndex,
    objectSpanMethod ({ rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    // 保存
    handleChange () {
      this.btnLoading = true;
      this.$axios
        .put("/api/PricesSystem", this.datas)
        .then((res) => {
          this.btnLoading = false;
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$emit('handleSecond');
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          console.log(error);
        });
    },
    // 提交上传商品文件
    submitUpload() {
      this.$refs.upload.submit();
    },
    // 文件状态改变时
    handleChangeXls(file, fileList) {
      console.log(fileList);
      // this.$refs.upload.clearFiles();
      // this.uploadFile = [{name:file.name,url:file.url}];
    },
    allUpLoad(param) {
      console.log(param);
      const _file = param.file;
      const formData = new FormData();
      formData.append("file", _file);
      this.fileLoad = true;
      this.$axios
        .post1(`/api/v3/PricesSystemUpload`,formData)
        .then((res) => {
          this.fileLoad = false;
          this.$alert(`${res.data.message}`, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.GoodsFile = false;
              this.$refs.upload.clearFiles();
            },
          });
        })
        .catch((err) => {
          this.fileLoad = false;
          this.$message({
            type: "error",
            message: err,
          });
        });
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  .prim {
    background-color: #3978f7;
    width: 120px;
  }
}
.GoodsFiles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 173px;
  .GoodsFiles_Top_left {
    margin-top: 10px;
    max-width: 198px;
  }
  .GoodsFiles_Top_right {
    width: 200px;
    p {
      font-size: 14px;
      color: #333333;
      line-height: 1.8;
    }
  }
  .GoodsFiles_selete {
    flex: 1;
    display: flex;
    min-width: 300px;
    margin: 15px 0;
    width: 100%;
    height: 45px;
    .el-select {
      margin: 0 10px;
    }
  }
  .GoodsFiles_footer {
    height: 40px;
    display: flex;
    min-width: 300px;
    width: 100%;
    justify-content: flex-end;
    padding: 0 10px;
  }
}
</style>
