<template>
  <div class="remind">
    <div class="task_name">
      <div class="task_name_text">任务名称<span style="color: red">*</span></div>
      <el-input placeholder="请输入任务名称" v-model="name" clearable> </el-input>
    </div>
    <div class="task_selete">
      <div class="task_selete_box">
        <div class="task_box_name">任务周期<span style="color: red">*</span></div>
        <div class="task_box_right">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="city in cities" :label="city" :key="city.name">
              {{ city.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="task_selete_box">
        <div class="task_box_name">任务时间<span style="color: red">*</span></div>
        <div class="task_box_right">
          <el-checkbox
            :indeterminate="isIndeterminate1"
            v-model="checkAll1"
            @change="handleCheckAllChange1"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="checkedCities1"
            @change="handleCheckedCitiesChange1"
          >
            <el-checkbox v-for="city in cities1" :label="city" :key="city.value">
              {{ city.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="task_Radio">
      <p>到手价的计算方式</p>
      <el-radio-group v-model="radio">
        <el-radio label="常规"
          >常规到手价=标价+附加费（进口税）-优惠（商品券、店铺券、满折券）</el-radio
        >
        <el-radio label="特殊1"
          >特殊到手价1=标价+附加费（进口税、快递费）-优惠（商品券、店铺券、满折券、淘金币）</el-radio
        >
        <el-radio label="特殊2"
          >特殊到手价2=标价+附加费（进口税）-优惠（商品券、店铺券、满折券、淘金币、跨店满减）</el-radio
        >
      </el-radio-group>
    </div>
    <div class="task_Radio">
      <p>价格状态比对设置</p>
      <div class="task_price">
        <span class="task_span">淘宝</span>
        <el-radio-group v-model="taobao_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">天猫</span>
        <el-radio-group v-model="tmall_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">京东</span>
        <el-radio-group v-model="jd_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">拼多多</span>
        <el-radio-group v-model="pinduoduo_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">抖音</span>
        <el-radio-group v-model="douyin_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">快手</span>
        <el-radio-group v-model="kuaishou_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">闲鱼</span>
        <el-radio-group v-model="xianyu_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">1688</span>
        <el-radio-group v-model="a1688_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">速卖通</span>
        <el-radio-group v-model="aliexpress_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
      <div class="task_price">
        <span class="task_span">唯品会</span>
        <el-radio-group v-model="vip_match">
          <el-radio v-model="radio" label="标价">标价</el-radio>
          <el-radio v-model="radio" label="到手价">到手价</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div>
      <el-button
        type="primary"
        class="submit"
        size="small"
        icon="el-icon-success"
        @click="handleSubmit"
        :loading="loading"
        >{{ data == null ? "保存" : "保存更新" }}</el-button
      >
      <el-button size="small" style="width: 80px" @click="handleBack" icon="el-icon-back"
        >返回</el-button
      >
    </div>
  </div>
</template>

<script>
const optionDate = [
  { name: "星期一", value: 1 },
  { name: "星期二", value: 2 },
  { name: "星期三", value: 3 },
  { name: "星期四", value: 4 },
  { name: "星期五", value: 5 },
  { name: "星期六", value: 6 },
  { name: "星期日", value: 0 },
];
const optionTime = [
  { name: "0点", value: 0 },
  { name: "1点", value: 1 },
  { name: "2点", value: 2 },
  { name: "3点", value: 3 },
  { name: "4点", value: 4 },
  { name: "5点", value: 5 },
  { name: "6点", value: 6 },
  { name: "7点", value: 7 },
  { name: "8点", value: 8 },
  { name: "9点", value: 9 },
  { name: "10点", value: 10 },
  { name: "11点", value: 11 },
  { name: "12点", value: 12 },
  { name: "13点", value: 13 },
  { name: "14点", value: 14 },
  { name: "15点", value: 15 },
  { name: "16点", value: 16 },
  { name: "17点", value: 17 },
  { name: "18点", value: 18 },
  { name: "19点", value: 19 },
  { name: "20点", value: 20 },
  { name: "21点", value: 21 },
  { name: "22点", value: 22 },
  { name: "23点", value: 23 },
];
export default {
  data() {
    return {
      data: null, //传输的值
      name: "", //名称
      checkAll: false,
      checkedCities: [],
      cities: optionDate,
      isIndeterminate: true,
      checkAll1: false,
      checkedCities1: [],
      cities1: optionTime,
      isIndeterminate1: true,
      radio: "常规",
      loading: false,
      taobao_match: "标价",
      tmall_match: "标价",
      jd_match: "标价",
      pinduoduo_match: "标价",
      douyin_match: "标价",
      kuaishou_match: "标价",
      xianyu_match: "标价",
      a1688_match: "标价",
      aliexpress_match: "标价",
      vip_match: "标价",
    };
  },
  created() {
    let data = this.$route.params.data;
    if (data) {
      this.data = data;
      this.name = data.name;
      if (data.scheme == null || data.scheme == "") {
        this.radio = "常规";
      } else {
        this.radio = data.scheme;
      }
      this.taobao_match = data.taobao_match;
      this.tmall_match = data.tmall_match;
      this.jd_match = data.jd_match;
      this.pinduoduo_match = data.pinduoduo_match;
      this.douyin_match = data.douyin_match;
      this.kuaishou_match = data.kuaishou_match;
      this.xianyu_match = data.xianyu_match;
      this.a1688_match = data.a1688_match;
      this.aliexpress_match = data.aliexpress_match;
      this.vip_match = data.vip_match;
      let week = data.week.split(",");
      let hour = data.hour.split(",");
      for (let i in optionDate) {
        for (let k in week) {
          if (optionDate[i].value == week[k]) {
            this.checkedCities.push(optionDate[i]);
          }
        }
      }
      for (let i in optionTime) {
        for (let k in hour) {
          if (optionTime[i].value == hour[k]) {
            this.checkedCities1.push(optionTime[i]);
          }
        }
      }
    }
  },
  methods: {
    //   任务周期
    handleCheckAllChange(val) {
      this.checkedCities = val ? optionDate : [];
      this.isIndeterminate = false;
    },
    //   任务周期
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    //   任务时间
    handleCheckAllChange1(val) {
      this.checkedCities1 = val ? optionTime : [];
      this.isIndeterminate1 = false;
    },
    //   任务时间
    handleCheckedCitiesChange1(value) {
      let checkedCount = value.length;
      this.checkAll1 = checkedCount === this.cities1.length;
      this.isIndeterminate1 = checkedCount > 0 && checkedCount < this.cities1.length;
    },
    //   保存
    handleSubmit() {
      if (this.name == "") {
        this.$message({
          message: "请输入任务名称",
          type: "warning",
        });
        return;
      }
      if (this.checkedCities.length == 0) {
        this.$message({
          message: "请选择任务周期",
          type: "warning",
        });
        return;
      }
      if (this.checkedCities1.length == 0) {
        this.$message({
          message: "请选择任务时间",
          type: "warning",
        });
        return;
      }
      let checkies = [];
      let checkies1 = [];
      for (let i in this.checkedCities) {
        checkies.push(this.checkedCities[i].value);
      }
      for (let i in this.checkedCities1) {
        checkies1.push(this.checkedCities1[i].value);
      }
      this.loading = true;
      if (this.data == null) {
        this.scheduleAdd(this.name, checkies, checkies1);
      } else {
        this.schedulePut(this.name, checkies, checkies1);
      }
    },
    // 修改
    schedulePut(name, checkies, checkies1) {
      this.$axios
        .put("/api/Schedule", {
          id: this.data.id,
          name,
          week: checkies.toString(),
          hour: checkies1.toString(),
          scheme: this.radio,
          update_time: this.data.update_time,
          create_time: this.data.create_time,
          jd_match: this.jd_match,
          taobao_match: this.taobao_match,
          tmall_match: this.tmall_match,
          pinduoduo_match: this.pinduoduo_match,
          douyin_match: this.douyin_match,
          kuaishou_match: this.kuaishou_match,
          xianyu_match: this.xianyu_match,
          a1688_match: this.a1688_match,
          aliexpress_match: this.aliexpress_match,
          vip_match: this.vip_match,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            this.$message({
              message: "任务更新成功",
              type: "success",
            });
            this.name = "";
            setTimeout(() => {
              this.$router.push({ name: "task" });
            }, 500);
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    // 添加
    scheduleAdd(name, checkies, checkies1) {
      this.$axios
        .add("/api/Schedule", {
          name,
          week: checkies.toString(),
          hour: checkies1.toString(),
          scheme: this.radio,
          jd_match: this.jd_match,
          taobao_match: this.taobao_match,
          tmall_match: this.tmall_match,
          pinduoduo_match: this.pinduoduo_match,
          douyin_match: this.douyin_match,
          kuaishou_match: this.kuaishou_match,
          xianyu_match: this.xianyu_match,
          a1688_match: this.a1688_match,
          aliexpress_match: this.aliexpress_match,
          vip_match: this.vip_match,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.name = "";
            setTimeout(() => {
              this.$router.push({ name: "task" });
            }, 500);
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    // 返回上一页
    handleBack() {
      this.$router.push({ name: "task" });
    },
  },
};
</script>

<style lang="scss" scoped>
.remind {
  width: 100%;
  min-width: 1392px;
  min-height: 500px;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 1000px;
  }
  .task_name {
    display: flex;
    width: 600px;
    align-items: center;
    .task_name_text {
      font-size: 14px;
      color: #333333;
      width: 100px;
    }
  }
  .task_selete {
    width: 800px;
    height: auto;
    padding: 20px;
    background-color: #f5f5f5;
    margin-top: 20px;
    .task_selete_box {
      display: flex;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      .task_box_name {
        width: 90px;
        font-size: 14px;
      }
      .task_box_right {
        flex: 1;
      }
    }
  }
}
.el-checkbox {
  margin-bottom: 15px;
  width: 60px;
}
.submit {
  width: 100px;
  background-color: #3978f7;
  margin-top: 24px;
}
.task_Radio {
  width: 800px;
  height: auto;
  margin-top: 30px;
  p {
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .el-radio {
    margin-bottom: 15px;
  }
  .task_price {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .task_span {
      line-height: 16px;
      width: 60px;
    }
    .el-radio {
      margin-bottom: 0;
      margin-top: 2px;
    }
  }
}
</style>
